/* ==== COLOR VARS ==== */
/* ==== FONTS IMPORT ==== */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'futurapt';
  src: url('../fonts/FuturaPT-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'futurabold';
  src: url('../fonts/FuturaPT-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* ==== GLOBAL SETTINGS ==== */
* {
  outline: none!important;
}
body {
  font-family: 'futurapt' !important;
  font-size: 16px;
  color: #484747;
  padding-top: 95px;
}
a:hover,
a:focus {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.executive-committee-header .col-xs-12 .list-menu select,
.top-xs-show,
.xs-menu,
.follow-us {
  display: none;
}
/* ==== HEADER ==== */
header {
  background: #fff;
  padding-bottom: 14px;
  height: 95px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 7;
}
header.active {
  top: -30px;
  transition: 0.3s;
}
header.active .primary-menu {
  padding-top: 12px;
}
header.active .primary-menu a.logo {
  position: relative;
  bottom: -9px;
  min-width: 120px;
  width: 120px;
}
header.active:hover {
  top: 0;
}
header .tools {
  padding: 3px 28px;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
  background: #f7f7fa;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  position: relative;
  z-index: 3;
}
header .tools li a {
  display: inline-block;
  color: #182d70;
  font-size: 15px;
  padding: 0 2px;
}
header .tools li a:hover {
  font-weight: 600;
}
header .tools li:last-child a {
  padding-right: 17px;
  background: url(../img/icons/icon-login.png) no-repeat right;
}
header .tools li select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #182d70;
  background: url(../img/icons/icon-drop.png) no-repeat right;
  border: none;
  cursor: pointer;
  padding: 0 16px 0 2px;
  font-size: 15px;
}
header .tools li select:hover {
  font-weight: 600;
}
header .primary-menu {
  padding: 0 33px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  top: -30px;
}
header .primary-menu a.logo {
  min-width: 140px;
  width: 140px;
}
header .primary-menu a.logo img {
  max-width: 100%;
}
header .primary-menu .menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .primary-menu .menu li {
  margin: 0 8px;
}
header .primary-menu .menu li a {
  font-family: 'futurabold';
  color: #182d70;
  transition: 0.4s;
  font-size: 18px;
  padding: 6px 13px;
}
header .primary-menu .menu li a svg {
  width: 22px;
  fill: #182d70;
  transition: 0.4s;
  height: 22px;
}
header .primary-menu .menu li a:hover {
  background: #334581;
  color: #fff;
}
header .primary-menu .menu li:last-child a {
  background: transparent;
}
header .primary-menu .menu li:last-child a svg {
  position: relative;
  bottom: -4px;
}
header .primary-menu .menu li:last-child a:hover svg {
  fill: #2c8ee0;
}
header .primary-menu .socials {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
header .primary-menu .socials li {
  margin-left: 14px;
}
header .primary-menu .socials li a svg {
  width: 18px;
  fill: #182d70;
  transition: 0.4s;
  max-height: 18px;
}
header .primary-menu .socials li a:hover svg {
  fill: #2c8ee0;
}
/* ==== TOP CAROUSEL ==== */
.top-carousel .owl-carousel .item img {
  min-height: 400px;
  object-fit: cover;
}
.top-carousel .owl-carousel .owl-prev button {
  position: absolute;
  border: none;
  width: 16px;
  height: 31px;
  background: url(../img/icons/prev.png) no-repeat center;
  background-size: 100%;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.top-carousel .owl-carousel .owl-next button {
  position: absolute;
  border: none;
  width: 16px;
  height: 31px;
  background: url(../img/icons/next.png) no-repeat center;
  background-size: 100%;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
/* ==== PARTNERS ==== */
.partners {
  background: rgba(233, 233, 241, 0.87);
  background: linear-gradient(to bottom, rgba(233, 233, 241, 0.87) 0%, #cecbd5 80%);
  padding: 7px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -90px;
  position: relative;
  z-index: 3;
}
.partners .left {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners .left li {
  margin-right: 60px;
}
.partners .right {
  display: flex;
  align-items: center;
}
.partners .right .partner p {
  font-size: 16px;
  color: #010833;
  font-family: 'Open Sans';
  font-weight: 300;
  margin-top: 5px;
}
.partners .right .timer {
  font-family: 'Open Sans';
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #010834;
}
.partners .right .timer > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 71px;
  margin-left: 8px;
}
.partners .right .timer > div u {
  width: 71px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  color: #010833;
  text-decoration: none;
  background: #fff;
  margin-top: 8px;
}
.partners .right .sub-message {
  color: #182d70;
  font-family: 'Open Sans';
  font-size: 24px;
  padding: 26px 10px;
}
/* ==== NEWS ==== */
.news {
  padding-top: 50px;
  padding-bottom: 70px;
  background: #e9e9f1;
}
.news .col-xs-12 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.news .col-xs-12 .news-h {
  font-family: 'futurabold';
  color: #182d70;
  font-size: 39px;
  line-height: 1;
  margin-bottom: 30px;
}
.news .col-xs-12 .news-col {
  width: 870px;
  min-width: 870px;
}
.news .col-xs-12 .news-col a {
  display: block;
  margin-bottom: 19px;
  border-radius: 5px;
  overflow: hidden;
}
.news .col-xs-12 .news-col a.large {
  font-family: 'futurapt';
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
  height: 420px;
  position: relative;
}
.news .col-xs-12 .news-col a.large img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
  transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  transform: scale(1);
}
.news .col-xs-12 .news-col a.large span.img-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: 50% 0;
}
.news .col-xs-12 .news-col a.large span.img-wrapper:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.news .col-xs-12 .news-col a.large:hover {
  transform: scale(1.02);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.news .col-xs-12 .news-col a.large .info {
  display: block;
  position: absolute;
  z-index: 3;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px 45px 24px 45px ;
}
.news .col-xs-12 .news-col a.large .info .highlight {
  background: rgba(0, 0, 0, 0.5);
  padding: 0 5px;
}
.news .col-xs-12 .news-col a.large .info .headig {
  display: block;
  font-size: 23px;
  line-height: 1;
  margin-bottom: 20px;
}
.news .col-xs-12 .news-col a.large .info .description {
  display: block;
  font-size: 28px;
  line-height: 1.1;
  margin-bottom: 20px;
}
.news .col-xs-12 .news-col a.large .info .xs-text {
  display: block;
  font-size: 22px;
  line-height: 1.1;
}
.news .col-xs-12 .news-col a.small {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: transparent;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}
.news .col-xs-12 .news-col a.small .img-wrapper {
  display: block;
  width: 420px;
  min-width: 420px;
  height: 195px;
  margin-right: 25px;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 0;
}
.news .col-xs-12 .news-col a.small .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  transform: scale(1);
}
.news .col-xs-12 .news-col a.small:hover {
  background: #fff;
  transform: scale(1.02);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.news .col-xs-12 .news-col a.small .info {
  display: block;
  font-family: 'futurapt';
  padding: 15px 30px 10px 0;
}
.news .col-xs-12 .news-col a.small .info .headig {
  display: block;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 20px;
  color: #2c8ee0;
}
.news .col-xs-12 .news-col a.small .info .description {
  display: block;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #182d70;
}
.news .col-xs-12 .news-col a.small .info .xs-text {
  display: block;
  font-size: 16px;
  line-height: 1.1;
  color: #484747;
}
.news .col-xs-12 .news-col a.more {
  display: inline-block;
  color: #182d70;
  font-size: 22px;
  margin-bottom: 0;
  border-radius: 0;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  line-height: 1;
}
.news .col-xs-12 .news-col a.more i {
  vertical-align: -1px;
}
.news .col-xs-12 .news-col a.more:hover {
  color: #6e70a0;
}
.news .col-xs-12 .projects-col {
  width: 250px;
  min-width: 250px;
}
.news .col-xs-12 .projects-col .card {
  display: block;
  width: 100%;
  height: 130px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 19px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.news .col-xs-12 .projects-col .card:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #122b58;
  opacity: 0.09;
  top: 0;
  left: 0;
}
.news .col-xs-12 .projects-col .card:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #122b58;
  opacity: 0.09;
  background-color: #2e2c7e;
  opacity: 0.33;
  top: 0;
  left: 0;
}
.news .col-xs-12 .projects-col .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  transform: scale(1);
}
.news .col-xs-12 .projects-col .card span {
  color: #fff;
  position: absolute;
  padding: 12px;
  font-family: 'futurabold';
  bottom: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  font-size: 17px;
}
.news .col-xs-12 .projects-col .card span i {
  color: #2c8ee0;
  position: relative;
  z-index: 10;
  font-size: 18px;
  vertical-align: -1px;
}
.news .col-xs-12 .projects-col .card span:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.6rem;
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.8));
}
.news .col-xs-12 .projects-col .card span u {
  text-decoration: none;
  position: relative;
  z-index: 10;
}
.news .col-xs-12 .projects-col .card:hover {
  transform: scale(1.02);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
/* ==== FANCERS ==== */
.top-fancers {
  min-height: 975px;
  background: url(../img/fancers-bg.jpg) no-repeat center;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 43px;
}
.top-fancers .col-xs-12 {
  padding: 0 57px;
}
.top-fancers .fancers-h {
  font-family: 'futurabold';
  text-align: center;
  line-height: 1;
  margin-bottom: 27px;
  color: #fff;
  font-size: 42px;
}
.top-fancers .subtitle {
  text-align: center;
  font-size: 26px;
  color: #fff;
  font-family: 'Open Sans';
  margin-bottom: 10px;
}
.top-fancers .flex-parent {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 37px;
}
.top-fancers .flex-parent .card {
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 5px;
  padding: 18px;
  font-family: 'Open Sans';
  width: 49%;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}
.top-fancers .flex-parent .card .img-wrapper {
  display: block;
  width: 137px;
  min-width: 137px;
  height: 137px;
  overflow: hidden;
  margin-right: 18px;
  background-color: #ccc;
}
.top-fancers .flex-parent .card .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
}
.top-fancers .flex-parent .card .info {
  display: block;
}
.top-fancers .flex-parent .card .info .name {
  display: block;
  color: #182d70;
  font-size: 21px;
  margin-bottom: 5px;
}
.top-fancers .flex-parent .card .info .description {
  font-size: 14px;
  color: #484747;
  line-height: 1.2;
  display: block;
  margin-bottom: 8px;
  min-height: 70px;
}
.top-fancers .flex-parent .card .info .description b {
  display: block;
  line-height: 1.4;
}
.top-fancers .flex-parent .card .info > u {
  text-decoration: none;
  color: #182d70;
  font-size: 17px;
  display: block;
}
.top-fancers .flex-parent .card .info > u i {
  vertical-align: -1px;
}
.top-fancers .flex-parent .card:hover {
  transform: scale(1.02);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.pools-results-table {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pools-results-table .table {
  width: 100%;
  margin-bottom: 0;
}
.pools-results-table .table tr th {
  font-family: 'futurabold';
  font-size: 15px;
  background: #182d70;
  color: #fff;
  border: none;
  text-align: center;
  padding: 15px 5px;
}
.pools-results-table .table tr th:first-child {
  border-top-left-radius: 8px;
}
.pools-results-table .table tr th:last-child {
  border-top-right-radius: 8px;
}
.pools-results-table .table tr td {
  background: #fff;
  border: 1px solid #efefef;
  color: #182d70;
  font-size: 15px;
  text-align: center;
  padding-top: 13px;
}
@media (max-width: 760px) {
  .pools-results-table .table tr .pools-results-table--cell-md {
    display: none;
  }
}
.pools-results-table .table tr:nth-child(odd) td {
  background: #efefef;
}
.pools-results-table .table--radius-no tr th:first-child {
  border-top-left-radius: 0;
}
.pools-results-table .table--radius-no tr th:last-child {
  border-top-right-radius: 0;
}
.pools-results-table--finals {
  padding-top: 0;
}
.pools-results-table--finals .table tr th,
.pools-results-table--finals .table tr td {
  text-align: left;
  padding-left: 20px;
}
/* ==== STATISTICS TABLE ==== */
.statistics-table {
  background-color: #e9e9f1;
  padding-top: 53px;
  padding-bottom: 80px;
}
.statistics-table--bg-no {
  background: none;
}
.statistics-table .table-h {
  font-size: 42px;
  color: #182d70;
  margin-bottom: 40px;
  font-family: 'futurabold';
  text-align: center;
  line-height: 1;
}
.statistics-table .col-xs-12 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.statistics-table .col-xs-12 .table-parent {
  width: 49.25%;
  border-radius: 8px;
}
.statistics-table .col-xs-12 .table-parent .table-parent-h {
  text-align: center;
  font-size: 26px;
  font-family: 'futurabold';
  margin-bottom: 10px;
  color: #182d70;
}
.statistics-table .col-xs-12 .table-parent .table {
  box-shadow: 3px 2px 12px #c7c7ce;
  width: 100%;
  margin-bottom: 0;
}
.statistics-table .col-xs-12 .table-parent .table tr th {
  font-family: 'futurabold';
  font-size: 15px;
  background: #182d70;
  color: #fff;
  border: none;
  text-align: center;
  padding: 15px 5px;
}
.statistics-table .col-xs-12 .table-parent .table tr th:first-child {
  border-top-left-radius: 8px;
  text-align: left;
  padding: 15px;
}
.statistics-table .col-xs-12 .table-parent .table tr th:first-child.Table-cell--centered,
.statistics-table .col-xs-12 .table-parent .table tr th.Table-cell--centered {
  text-align: center;
}
.statistics-table .col-xs-12 .table-parent .table tr th:last-child {
  border-top-right-radius: 8px;
  padding-right: 15px;
}
.statistics-table .col-xs-12 .table-parent .table tr td {
  background: #fff;
  border: 1px solid #efefef;
  color: #182d70;
  font-size: 15px;
  text-align: center;
  padding-top: 13px;
}
.statistics-table .col-xs-12 .table-parent .table tr td:first-child {
  text-align: left;
  padding: 7px 15px;
}
.statistics-table .col-xs-12 .table-parent .table tr td u {
  text-decoration: none;
  display: block;
  line-height: 1;
  font-size: 12px;
  color: #797979;
}
.statistics-table .col-xs-12 .table-parent .table tr:nth-child(odd) td {
  background: #efefef;
}
.statistics-table .col-xs-12 .table-parent .table--radius-no tr th:first-child {
  border-top-left-radius: 0;
}
.statistics-table .col-xs-12 .table-parent .table--radius-no tr th:last-child {
  border-top-right-radius: 0;
}
.statistics-table .col-xs-12 .table-parent .text-center {
  background: #fff;
  border-radius: 0 0 7px 7px;
  padding: 15px;
  box-shadow: 3px 2px 12px #c7c7ce;
}
.statistics-table .col-xs-12 .table-parent .text-center .more {
  display: inline-block;
  color: #182d70;
  font-size: 19px;
  margin-bottom: 0;
  border-radius: 0;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  line-height: 1;
}
.statistics-table .col-xs-12 .table-parent .text-center .more i {
  vertical-align: -1px;
}
.statistics-table .col-xs-12 .table-parent .text-center .more:hover {
  color: #6e70a0;
}
/* ==== VIDEO PLAYER ==== */
.video-player {
  min-height: 1010px;
  background: url(../img/fancers-bg.jpg) no-repeat center;
  background-size: cover;
  padding-top: 55px;
  padding-bottom: 43px;
  position: relative;
}
.video-player .player-h {
  font-size: 50px;
  color: #fff;
  font-family: futurabold;
  padding-left: 100px;
  background: url(../img/icons/youtube.png) no-repeat left 10px;
  background-size: 66px;
  max-width: 395px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.video-player .flex-parent {
  display: flex;
  align-items: stretch;
  background: #fff;
}
.video-player .flex-parent .video {
  padding: 20px;
  font-size: 0;
  width: 730px;
  height: 430px;
}
.video-player .flex-parent .video iframe {
  width: 0%!important;
  height: 0%!important;
  display: none;
  border: none;
}
.video-player .flex-parent .video iframe.active {
  width: 100%!important;
  height: 100%!important;
  display: block;
  animation: fadeIn 5s 1;
}
.video-player .flex-parent .video-list {
  overflow-y: scroll;
  max-height: 430px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 410px;
}
.video-player .flex-parent .video-list .list-link {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: #e8e8e8;
  border-bottom: 1px solid #dcdcdc;
  padding: 8px 18px;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}
.video-player .flex-parent .video-list .list-link:last-child {
  border: none;
}
.video-player .flex-parent .video-list .list-link img {
  width: 110px;
  height: 81px;
  object-fit: cover;
  margin-right: 12px;
}
.video-player .flex-parent .video-list .list-link .info {
  display: block;
  padding-top: 3px;
}
.video-player .flex-parent .video-list .list-link .info u {
  display: block;
  text-decoration: none;
  font-size: 14px;
  color: #a0a2a6;
  margin-bottom: 3px;
}
.video-player .flex-parent .video-list .list-link .info i {
  display: block;
  font-style: normal;
  font-size: 18px;
  color: #182d70;
}
.video-player .flex-parent .video-list .list-link .info em {
  font-style: normal;
  color: #2c8ee0;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 3px;
  display: none;
}
.video-player .flex-parent .video-list .list-link.active {
  background: #fff;
  border-color: #fff;
}
.video-player .flex-parent .video-list .list-link.active em {
  display: block;
}
.video-player .flex-parent .video-list .list-link.active u {
  display: none;
}
.video-player .flex-parent .video-list .list-link.active img {
  border: 3px solid #2c8ee0;
}
.video-player .flex-parent .video-list .list-link:hover {
  background: #fff;
  border-color: #fff;
}
.video-player .olympic {
  color: #fff;
  position: absolute;
  width: 100%;
  padding: 30px 0 50px 0;
  text-align: center;
  background: rgba(72, 206, 238, 0.2);
  left: 0;
  bottom: 0;
}
.video-player .olympic > p {
  font-size: 62px;
  text-transform: uppercase;
}
.video-player .olympic > p span {
  font-family: 'futurabold';
}
/* ==== SOCIAL WALL ==== */
.social-wall {
  background: #cdcdcf;
  padding-top: 65px;
  padding-bottom: 45px;
}
.social-wall .wall-h {
  font-size: 42px;
  text-align: center;
  line-height: 1;
  font-family: 'futurabold';
  color: #182d70;
}
.social-wall .social-indicators {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  max-width: 540px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 6px;
  margin-top: 27px;
  box-shadow: 0px 2px 12px #b3b3bb;
  margin-bottom: 65px;
}
.social-wall .social-indicators label {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 0;
  cursor: pointer;
  color: #182d70;
  padding-left: 0;
}
.social-wall .social-indicators label.check {
  color: #dadada;
}
.social-wall .social-indicators label span {
  display: block;
  position: relative;
  top: 2px;
}
.social-wall .social-indicators label input[type="checkbox"],
.social-wall .social-indicators label input[type="radio"] {
  position: absolute;
  z-index: -10;
  width: 0;
  height: 0;
}
.social-wall .social-indicators label input[type="checkbox"] + i,
.social-wall .social-indicators label input[type="radio"] + i {
  border-radius: 5px;
  margin-right: 11px;
}
.social-wall .social-indicators label i {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #182d70;
  position: relative;
}
.social-wall .social-indicators label i:before {
  content: "";
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background: url(../img/icons/check2.png) no-repeat center;
  background-size: 12px 9px;
  background-color: #fff;
  border-radius: 5px;
  transform: scale(0);
  transition: 200ms;
}
.social-wall .social-indicators label input:checked + i {
  border: 1px solid #182d70;
}
.social-wall .social-indicators label input:checked + i:before {
  transform: scale(1.1);
}
.social-wall .social-share .card {
  display: block;
  text-align: center;
  max-width: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 2px 12px #b3b3bb;
  margin-bottom: 30px;
  transition: transform 0.4s, box-shadow 0.4s, -webkit-transform 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.21);
}
.social-wall .social-share .card:hover {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.45);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.social-wall .social-share .card img {
  width: 100%;
}
.social-wall .social-share .card span {
  display: block;
}
.social-wall .social-share .card span u {
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #4d4e4f;
}
.social-wall .social-share .card span.info {
  font-size: 20px;
  color: #4d4e4f;
  padding: 10px 5px 25px 5px;
}
.social-wall .social-share .card span.soc {
  text-align: center;
}
.social-wall .social-share .card span.soc i {
  font-style: normal;
  display: inline-block;
  padding: 14px 0 14px 35px;
  color: #fff;
}
.social-wall .social-share .card.facebook .soc {
  background: #3c539a;
}
.social-wall .social-share .card.facebook .soc i {
  background: url(../img/icons/facebook.png) no-repeat left;
}
.social-wall .social-share .card.instagram .soc {
  background: #00548a;
}
.social-wall .social-share .card.instagram .soc i {
  background: url(../img/icons/instagram.png) no-repeat left;
}
.social-wall .social-share .card.twitter .soc {
  background: #33c5ef;
}
.social-wall .social-share .card.twitter .soc i {
  background: url(../img/icons/twitter.png) no-repeat left;
}
.social-wall .social-share .card.hide {
  display: none;
}
a.section-link {
  min-height: 237px;
  display: block;
  padding: 60px 15px 15px 15px;
  text-align: center;
  background: url(../img/executive-committee-header-bg.jpg) no-repeat top right;
  background-color: #010239;
  color: #fff;
}
a.section-link u {
  display: block;
  text-decoration: none;
  font-style: 24px;
  margin-bottom: 14px;
}
a.section-link span {
  font-size: 56px;
  line-height: 1;
}
a.section-link span i {
  color: #2c8ee0;
  vertical-align: -2px;
  font-size: 50px;
}
/* ==== FOOTER ==== */
footer {
  background: #000000;
  padding: 33px 0;
}
footer .container .col-xs-12 ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0 0 30px 0;
}
footer .container .col-xs-12 ul li {
  margin-right: 40px;
}
footer .container .col-xs-12 ul li:last-child {
  margin-right: 0;
}
footer .container .col-xs-12 ul li a {
  font-family: 'futurabold';
  color: #fff;
  transition: 0.4s;
  font-size: 22px;
}
footer .container .col-xs-12 ul li a:hover {
  color: #2c8ee0;
}
footer .container .col-xs-12 .copy {
  color: #505050;
  font-size: 16px;
  text-align: center;
}
/* ==== PAGE ATHLETES RANKINGS ==== */
.athletes-rankings {
  background: url(../img/Athletes_ranking_bg.jpg) no-repeat center;
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 52px;
}
.athletes-rankings--bg-no {
  background: none;
  padding-top: 0;
  padding-bottom: 30px;
}
.athletes-rankings .flex-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.athletes-rankings .flex-parent .athletes-rankings-h {
  font-family: 'futurabold';
  line-height: 1;
  color: #fff;
  font-size: 35px;
}
.athletes-rankings .flex-parent .links a {
  display: inline-block;
  line-height: 54px;
  text-align: center;
  padding: 0 26px;
  border-radius: 5px;
  font-size: 20px;
  color: #fff;
  background: #4f5672;
  transition: 0.4s;
}
.athletes-rankings .flex-parent .links a:hover {
  color: #182d70;
  background: #dbdbdb;
}
.athletes-rankings .flex-parent .links a.active {
  color: #182d70;
  background: #fff;
  position: relative;
  z-index: 2;
  margin-right: -10px;
}
.athletes-rankings .filter-wrapper {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 40px;
}
.athletes-rankings .filter-wrapper .top-row {
  position: relative;
  background: #dbdbdb;
  padding: 20px;
}
.athletes-rankings .filter-wrapper .top-row .Search-dropdown {
  width: 100%;
  background-color: white;
  line-height: 46px;
  padding: 0 10px;
  border-radius: 0;
  border-radius: 2px;
  border-color: #adadac;
  color: #182d70;
  height: 46px;
  padding: 0 14px;
  font-size: 18px;
  margin-right: 15px;
  font-family: inherit;
}
.athletes-rankings .filter-wrapper .top-row .search-row {
  display: flex;
  padding: 0 40px 0 0;
  border-right: 1px solid #adadad;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .athletes-rankings .filter-wrapper .top-row .search-row {
    border-right: none;
    padding: 0;
    display: block;
  }
}
.athletes-rankings .filter-wrapper .top-row .search-row--border-no {
  border-right: none;
}
.athletes-rankings .filter-wrapper .top-row .search-container {
  width: 100%;
  position: relative;
  background: url(../img/icons/zoom.png) no-repeat left;
  padding-left: 37px;
}
.athletes-rankings .filter-wrapper .top-row .search-container .Search-autocomplete {
  top: 46px;
  left: 37px;
  width: auto;
  right: 0;
}
.athletes-rankings .filter-wrapper .top-row .search-container input {
  line-height: 27px;
  height: 46px;
  border: none;
  font-size: 24px;
  width: 100%;
  background: #fff;
  color: #182d70;
}
.athletes-rankings .filter-wrapper .top-row .search-container input::-webkit-input-placeholder {
  color: #182d70;
}
.athletes-rankings .filter-wrapper .top-row .search-container input::-moz-placeholder {
  color: #182d70;
}
.athletes-rankings .filter-wrapper .top-row .search-container input:-ms-input-placeholder {
  color: #182d70;
}
.athletes-rankings .filter-wrapper .top-row .search-container input:-moz-placeholder {
  color: #182d70;
}
.athletes-rankings .filter-wrapper .top-row .calendar-links {
  display: flex;
}
.athletes-rankings .filter-wrapper .top-row .calendar-links a {
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  color: #fff;
  background: #364883;
  border: none;
  font-size: 20px;
  line-height: 46px;
  padding: 0 26px;
  min-width: auto;
}
@media screen and (max-width: 992px) {
  .athletes-rankings .filter-wrapper .top-row .calendar-links {
    justify-content: center;
    padding-top: 10px;
  }
}
.athletes-rankings .filter-wrapper .top-row .check-container {
  display: flex;
  align-items: center;
}
.athletes-rankings .filter-wrapper .top-row .check-container span {
  display: block;
  font-size: 18px;
  line-height: 1;
  color: #182d70;
  margin-right: 7px;
}
.athletes-rankings .filter-wrapper .top-row .check-container .check {
  display: block;
  position: relative;
  width: 28px;
  height: 13.3px;
  -webkit-appearance: none;
  background: #fff;
  outline: none;
  border-radius: 60px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border: 1px solid #182d70;
  cursor: pointer;
}
.athletes-rankings .filter-wrapper .top-row .check-container .check:checked {
  background: #fff;
}
.athletes-rankings .filter-wrapper .top-row .check-container .check:before {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  top: 0;
  left: 1px;
  transform: scale(0.8);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  background: #182d70;
  transition: 0.3s;
}
.athletes-rankings .filter-wrapper .top-row .check-container .check:checked:before {
  left: 50%;
}
.athletes-rankings .filter-wrapper .top-row .check-container [type="submit"] {
  border: none;
  background: #ffffff;
  color: #182d70;
  font-size: 20px;
  line-height: 46px;
  padding: 0 26px;
  margin-left: 20px;
  border-radius: 5px;
}
.athletes-rankings .filter-wrapper .top-row .check-container [type="submit"].active {
  display: block;
  animation: zoomIn 0.2s 1;
}
@media screen and (max-width: 991px) {
  .athletes-rankings .filter-wrapper .top-row .check-container [type="submit"] {
    margin-left: 0;
    width: 100%;
  }
}
.athletes-rankings .filter-wrapper .top-row .check-container .change-to-input {
  background: #364883;
  color: #fff;
}
.athletes-rankings .filter-wrapper .sub-row {
  background: #fff;
  padding: 20px 20px 25px 20px;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #dbdbdb;
  padding-left: 30px;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 0;
  cursor: pointer;
  padding-left: 0;
  margin-right: 18px;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label span {
  display: block;
  position: relative;
  top: 2px;
  color: #adadac;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label input[type="checkbox"],
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label input[type="radio"] {
  position: absolute;
  z-index: -10;
  width: 0;
  height: 0;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label input[type="checkbox"] + i,
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label input[type="radio"] + i {
  border-radius: 5px;
  margin-right: 11px;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label i {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #adadac;
  position: relative;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label i:before {
  content: "";
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background: url(../img/icons/check2.png) no-repeat center;
  background-size: 12px 9px;
  background-color: #fff;
  border-radius: 5px;
  transform: scale(0);
  transition: 200ms;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label input:checked + i {
  border: 1px solid #182d70;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label input:checked + i:before {
  transform: scale(1.1);
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label input:checked + i + span {
  color: #182d70;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators:first-child {
  padding-left: 0;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators:last-child {
  border-right: none;
}
.athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators:last-child label:last-child {
  margin-right: 0;
}
.athletes-rankings .filter-wrapper .sub-row .select-row {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.athletes-rankings .filter-wrapper .sub-row .select-row select {
  width: 310px;
  height: 50px;
  padding-left: 30px;
  padding-right: 35px;
  border: 1px solid #adadac;
  border-radius: 2px;
  margin-right: 55px;
  font-size: 18px;
  color: #182d70;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../img/icons/icon-down.png) no-repeat 90%;
}
.athletes-rankings .filter-wrapper .sub-row .select-row a.download {
  font-size: 20px;
  color: #182d70;
  display: inline-block;
  line-height: 54px;
  padding: 0 80px;
  box-shadow: 0 2px 11px #d9d9d9;
}
.athletes-rankings .filter-wrapper .sub-row .select-row a.download i {
  display: inline-block;
  margin-left: 10px;
}
.athletes-rankings .filter-wrapper .sub-row .select-row a.download.min {
  font-size: 20px;
  color: #182d70;
  display: inline-block;
  line-height: 54px;
  padding: 0 40px;
  margin-left: 10px;
  box-shadow: 0 2px 11px #d9d9d9;
}
.athletes-rankings .filter-wrapper .sub-row .select-row a.download.min i {
  display: inline-block;
  margin-left: 10px;
}
.athletes-rankings .filter-wrapper .sub-row .select-row.Search-options--advanced {
  display: none;
}
.athletes-rankings .filter-wrapper .sub-row .select-row.Search-options--advanced.Search-options--open {
  display: flex;
}
.athletes-rankings .flex-cards {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.athletes-rankings .flex-cards .card {
  display: block;
  padding: 14px;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  transition: transform 0.4s, box-shadow 0.4s, -webkit-transform 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.21);
  position: relative;
  width: 32.3%;
}
.athletes-rankings .flex-cards .card:hover {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.45);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.athletes-rankings .flex-cards .card .cdn-normalize {
  display: block;
  margin-bottom: 15px;
  width: 100%;
  height: 260px;
}
.athletes-rankings .flex-cards .card span.Flag-icon {
  width: 25px;
  height: 19px;
  object-fit: cover;
  position: absolute;
  z-index: 3;
  right: 14px;
  top: 14px;
}
.athletes-rankings .flex-cards .card span img {
  display: none;
  width: 25px;
  height: 17px;
  object-fit: cover;
  position: absolute;
  z-index: 3;
  right: 14px;
  top: 14px;
}
.athletes-rankings .flex-cards .card .name {
  font-family: 'futurabold';
  font-size: 23px;
  color: #182d70;
}
.athletes-rankings .flex-cards .card .bio {
  font-size: 16px;
}
.athletes-rankings .flex-cards .card .count {
  font-family: 'futurabold';
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 52px;
  color: #182d70;
  background: url(../img/icons/ol.png) no-repeat center;
  margin: 20px auto;
}
.athletes-rankings .flex-cards .card .count.c2 {
  background: url(../img/icons/ol2.png) no-repeat center;
}
.athletes-rankings .flex-cards .card .count.c3 {
  background: url(../img/icons/ol3.png) no-repeat center;
}
.athletes-rankings .flex-cards .card .points {
  font-size: 21px;
  color: #182d70;
}
.athletes-rankings .flex-cards .card .points b {
  font-family: 'futurabold';
  font-weight: normal;
}
.athletes-rankings .flex-cards--wrap {
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}
.athletes-rankings .flex-cards--wrap .card {
  margin: 0 10px 20px;
  width: 30%;
}
.athletes-rankings .filter-wrapper .top-row .is-active + .check-container input[type=submit] {
  display: block !important;
}
.athletes-rankings-table {
  background: #e9e9f1;
  padding: 30px 0 40px 0;
}
.athletes-rankings-table--bg-no {
  background: none;
  padding: 0;
}
.athletes-rankings-table .statistics-table {
  padding-top: 0;
  padding-bottom: 0;
}
.athletes-rankings-table .statistics-table .Table-description {
  font-size: 18px;
}
.athletes-rankings-table .statistics-table .Container-noContentMessage {
  font-size: 24px;
  text-align: center;
}
.athletes-rankings-table .statistics-table .SearchLog {
  color: #364883;
  font-size: 24px;
}
.athletes-rankings-table .statistics-table .col-xs-12 {
  display: block;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent {
  width: 100%;
  border-radius: 8px;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table-parent-h {
  text-align: center;
  font-size: 26px;
  font-family: 'futurabold';
  margin-bottom: 10px;
  color: #182d70;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table {
  box-shadow: 3px 2px 12px #c7c7ce;
  width: 100%;
  margin-bottom: 0;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th {
  font-family: 'futurabold';
  font-size: 18px;
  background: #182d70;
  color: #fff;
  border: none;
  text-align: center;
  padding: 10px 15px;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th:first-child {
  text-align: left;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th:first-child.Table-cell--centered,
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th.Table-cell--centered {
  text-align: center;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th.head-rank {
  text-align: center;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr td {
  background: #fff;
  border: 1px solid #efefef;
  color: #182d70;
  font-size: 18px;
  text-align: center;
  padding-top: 13px;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr td img {
  border-radius: 21px;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr:nth-child(odd) td {
  background: #efefef;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr:hover td {
  background: #dedbdb;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table--shadow-no {
  box-shadow: none;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table--shadow-no tr th {
  border-top: 1px solid white;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .text-center {
  background: #fff;
  border-radius: 0 0 7px 7px;
  padding: 15px;
  box-shadow: 3px 2px 12px #c7c7ce;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .text-center ul.pager {
  margin: 0 auto;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .text-center ul.pager li {
  font-size: 24px;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .text-center ul.pager li a {
  border: none;
  color: #182d70;
  border-radius: 0;
}
.athletes-rankings-table .statistics-table .col-xs-12 .table-parent .text-center ul.pager li span {
  color: #182d70;
  border: none;
  font-family: 'futurabold';
}
.athletes-rankings-table--padding-no {
  padding-top: 0;
}
.development--container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
@media screen and (max-width: 480px) {
  .development--container {
    display: block;
  }
}
.development--card {
  flex: 0 0 auto;
}
.development .text-center {
  padding: 15px;
}
.development .text-center ul.pager {
  margin: 0 auto;
}
.development .text-center ul.pager li {
  font-size: 24px;
}
.development .text-center ul.pager li a {
  border: none;
  color: #182d70;
  border-radius: 0;
  background: none;
}
.development .text-center ul.pager li a:hover {
  background: #fff;
}
.development .text-center ul.pager li span {
  color: #182d70;
  border: none;
  background: none;
  font-family: 'futurabold';
}
.development .text-center ul.pager li .next,
.development .text-center ul.pager li .prev {
  font-family: 'futurapt';
}
/* ==== Championnats Asiatiques ==== */
.championnats-asiatiques {
  background: #e9e9f1;
  padding: 75px 0 65px 0;
}
.championnats-asiatiques .date {
  font-size: 31px;
  line-height: 1;
  color: #182d70;
  text-align: center;
  margin-bottom: 10px;
}
.championnats-asiatiques .championnats-asiatiques-h {
  font-family: 'futurabold';
  font-size: 65px;
  text-align: center;
  line-height: 1;
  margin-bottom: 50px;
  color: #182d70;
}
.championnats-asiatiques .flex-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.championnats-asiatiques .flex-parent > p {
  font-size: 23px;
  line-height: 1;
  border-radius: 5px;
  background: #fff;
  color: #182d70;
  padding: 13px 40px;
  text-align: center;
  min-width: 230px;
}
.championnats-asiatiques .flex-parent > p b {
  font-family: 'futurabold';
  font-weight: normal;
}
.championnats-asiatiques .overview {
  box-shadow: 3px 2px 12px #c7c7ce;
  padding: 45px 50px;
  background: #fff;
  border-top: 2px solid #2c8ee0;
  border-radius: 5px;
}
.championnats-asiatiques .overview .overview-h {
  text-align: center;
  color: #182d70;
  font-size: 45px;
  font-family: 'futurabold';
  line-height: 1;
  margin-bottom: 10px;
}
.championnats-asiatiques .overview .flex-overview {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.championnats-asiatiques .overview .flex-overview .left > p {
  color: #182d70;
  font-size: 28px;
  margin-bottom: 60px;
}
.championnats-asiatiques .overview .flex-overview .left > p:last-child {
  margin-bottom: 0;
}
.championnats-asiatiques .overview .flex-overview .left > p b {
  font-family: 'futurabold';
  font-weight: normal;
  display: block;
}
.championnats-asiatiques .overview .flex-overview .center {
  color: #182d70;
}
.championnats-asiatiques .overview .flex-overview .center > div {
  margin-bottom: 75px;
}
.championnats-asiatiques .overview .flex-overview .center > div:last-child {
  margin-bottom: 0;
}
.championnats-asiatiques .overview .flex-overview .center > div p {
  line-height: 1;
  font-size: 27px;
  margin-bottom: 14px;
}
.championnats-asiatiques .overview .flex-overview .center > div b {
  display: block;
  line-height: 1;
  font-size: 27px;
  font-family: 'futurabold';
  font-weight: normal;
}
.championnats-asiatiques .overview .flex-overview .center > div b img {
  vertical-align: -2px;
}
.championnats-asiatiques .overview .flex-overview .right {
  display: flex;
  flex-direction: column;
}
.championnats-asiatiques .overview .flex-overview .right a {
  display: inline-block;
  font-size: 20px;
  color: #182d70;
  margin-bottom: 14px;
  box-shadow: 0 2px 11px #d9d9d9;
  padding: 12px 25px;
  text-align: center;
  font-size: 19px;
}
.championnats-asiatiques .overview .flex-overview .right a:last-child {
  margin-bottom: 0;
}
.championnats-asiatiques .overview .flex-overview .right a i {
  display: inline-block;
  margin-left: 8px;
}
/* ==== Clean Sport ==== */
.clean-sport-header {
  min-height: 293px;
  background: url(../img/smog-banner-dark.jpg) no-repeat center;
  padding: 110px 15px 15px 15px;
}
.clean-sport-header .clean-sport-header-h {
  font-family: 'futurabold';
  text-align: center;
  color: #fff;
  font-size: 73px;
  line-height: 1;
}
.clean-sport-flex-parent {
  background: #e9e9f1;
  padding: 40px 0 5px 0;
}
.clean-sport-flex-parent .col-xs-12 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.clean-sport-flex-parent .col-xs-12 .left {
  padding-right: 50px;
  width: 100%;
}
.clean-sport-flex-parent .col-xs-12 .left > div {
  margin-bottom: 60px;
}
.clean-sport-flex-parent .col-xs-12 .left > div > p {
  font-family: 'futurabold';
  font-size: 24px;
  color: #182d70;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.clean-sport-flex-parent .col-xs-12 .left > div > a {
  display: block;
  color: #182d70;
  font-size: 17px;
  padding: 15px;
  background: #e0e0ec;
  margin-bottom: 12px;
  transition: box-shadow 0.2s, background-color 0.2s;
}
.clean-sport-flex-parent .col-xs-12 .left > div > a:hover {
  background-color: #fff;
  box-shadow: 0 1.5rem 3.5rem -0.5rem rgba(44, 39, 109, 0.1);
}
.clean-sport-flex-parent .col-xs-12 .right .heading {
  font-family: 'futurabold';
  font-size: 20px;
  display: inline-block;
  border-bottom: 2px solid #2c8ee0;
  color: #182d70;
  margin-bottom: 25px;
}
.clean-sport-flex-parent .col-xs-12 .right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.clean-sport-flex-parent .col-xs-12 .right ul li a {
  color: #182d70;
  display: inline-block;
  font-size: 20px;
  padding: 11px 0;
  line-height: 1.1;
  border-bottom: 1px solid #c9c9cf;
  display: block;
  transition: 0.4s;
}
.clean-sport-flex-parent .col-xs-12 .right ul li a:hover {
  color: #2c8ee0;
}
.clean-sport-flex-parent .col-xs-12 .right ul li.active a {
  font-family: 'futurabold';
}
.clean-sport-flex-parent .col-xs-12 .right ul li:last-child a {
  border-bottom: none;
}
/* ==== Executive Committee ==== */
.executive-committee-header {
  padding: 60px 0 35px 0;
  background: url(../img/executive-committee-header-bg.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
}
.executive-committee-header--small {
  padding-top: 30;
}
.executive-committee-header .col-xs-12 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.executive-committee-header .col-xs-12 .info {
  max-width: auto;
  width: 100%;
}
.executive-committee-header .col-xs-12 .info h1 {
  font-family: 'futurabold';
  font-size: 55px;
  color: #fff;
  line-height: 1;
  max-width: 90%;
}
.executive-committee-header .col-xs-12 .info p,
.executive-committee-header .col-xs-12 .info .sub_text {
  display: inline-block;
  font-size: 25px;
  line-height: 1.2;
  margin-top: 10px;
}
.executive-committee-header .col-xs-12 .list-menu a.heading {
  font-size: 20px;
  display: inline-block;
  border-bottom: 2px solid #2c8ee0;
  font-family: 'futurabold';
  color: #fff;
  margin-bottom: 15px;
}
.executive-committee-header .col-xs-12 .list-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.executive-committee-header .col-xs-12 .list-menu ul li a {
  display: block;
  font-size: 20px;
  line-height: 1;
  padding: 18px 0;
  color: #fff;
  border-bottom: 1px solid #fff;
  transition: 0.4s;
  font-family: inherit;
}
.executive-committee-header .col-xs-12 .list-menu ul li a:hover {
  color: #2c8ee0;
}
.executive-committee-header .col-xs-12 .list-menu ul li.active a {
  color: #fff;
  font-family: 'futurabold';
}
.executive-committee-header .media-res-menu {
  position: absolute;
  left: 30px;
  padding: 10px 0 0;
  margin: 0;
  list-style: none;
  bottom: 20px;
}
.executive-committee-header .media-res-menu li {
  display: inline-block;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid white;
}
.executive-committee-header .media-res-menu li:last-child {
  border-right: none;
}
.executive-committee-header .media-res-menu li a {
  font-size: 18px;
  color: inherit;
  transition: 0.4s;
}
.executive-committee-header .media-res-menu li a:hover {
  color: #2c8ee0;
}
@media screen and (max-width: 768px) {
  .executive-committee-header .media-res-menu li a {
    font-size: 14px;
  }
}
.executive-committee-header .media-res-menu li.active a {
  font-family: 'futurabold';
}
@media screen and (max-width: 768px) {
  .executive-committee-header .media-res-menu {
    position: static;
    padding: 0 0 25px 0;
    width: 100%;
    display: block;
  }
  .executive-committee-header .media-res-menu li {
    display: block;
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    border-top: 1px solid #757575;
  }
  .executive-committee-header .media-res-menu li:last-child {
    border-bottom: 1px solid white;
  }
  .executive-committee-header .media-res-menu a {
    display: block;
    padding: 10px 20px;
  }
}
.athletes-rankings.results-competitions--padding-no {
  padding-top: 0;
}
.athletes-rankings.results-competitions .col-xs-12 .links {
  padding: 15px 0 20px 0;
  margin: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid white;
}
.athletes-rankings.results-competitions .col-xs-12 .links input[type=radio] {
  width: 1px;
  height: 1px;
  visibility: hidden;
  position: absolute;
  left: -999px;
  top: -999px;
  z-index: -1000;
}
.athletes-rankings.results-competitions .col-xs-12 .links input[type=radio]:checked + label {
  font-family: futurabold;
}
.athletes-rankings.results-competitions .col-xs-12 .links label {
  display: inline-block;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid white;
  cursor: pointer;
  font-size: 18px;
  color: white;
  transition: 0.4s;
  font-weight: 100;
  margin-bottom: 0;
}
.athletes-rankings.results-competitions .col-xs-12 .links label:hover {
  color: #2c8ee0;
}
.athletes-rankings.results-competitions .col-xs-12 .links label:last-child {
  border-right: none;
}
@media screen and (max-width: 768px) {
  .athletes-rankings.results-competitions .col-xs-12 .links {
    padding: 15px 0 25px 0;
    width: 100%;
    display: block;
    margin-bottom: 0;
    border-bottom: none;
  }
  .athletes-rankings.results-competitions .col-xs-12 .links label {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    border-top: 1px solid #757575;
    display: block;
    padding: 10px 20px;
  }
  .athletes-rankings.results-competitions .col-xs-12 .links label:last-child {
    border-bottom: 1px solid white;
  }
  .athletes-rankings.results-competitions .col-xs-12 .links a {
    display: block;
    padding: 10px 20px;
  }
}
.wach-main-player .media-res-menu,
.athletes-rankings .media-res-menu {
  padding: 15px 0 20px 0;
  margin: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid white;
  list-style: none;
}
.wach-main-player .media-res-menu li,
.athletes-rankings .media-res-menu li {
  display: inline-block;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid white;
}
.wach-main-player .media-res-menu li:last-child,
.athletes-rankings .media-res-menu li:last-child {
  border-right: none;
}
.wach-main-player .media-res-menu li a,
.athletes-rankings .media-res-menu li a {
  font-size: 18px;
  color: white;
  transition: 0.4s;
}
.wach-main-player .media-res-menu li a:hover,
.athletes-rankings .media-res-menu li a:hover {
  color: #2c8ee0;
}
@media screen and (max-width: 768px) {
  .wach-main-player .media-res-menu li a,
  .athletes-rankings .media-res-menu li a {
    font-size: 14px;
  }
}
.wach-main-player .media-res-menu li.active a,
.athletes-rankings .media-res-menu li.active a {
  font-family: 'futurabold';
}
@media screen and (max-width: 768px) {
  .wach-main-player .media-res-menu,
  .athletes-rankings .media-res-menu {
    position: static;
    padding: 15px 0 25px 0;
    width: 100%;
    display: block;
    border-bottom: none;
    margin-bottom: 0;
  }
  .wach-main-player .media-res-menu li,
  .athletes-rankings .media-res-menu li {
    display: block;
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    border-top: 1px solid #757575;
  }
  .wach-main-player .media-res-menu li:last-child,
  .athletes-rankings .media-res-menu li:last-child {
    border-bottom: 1px solid white;
  }
  .wach-main-player .media-res-menu a,
  .athletes-rankings .media-res-menu a {
    display: block;
    padding: 10px 20px;
  }
}
.Document-content .executive-committee {
  padding: 0 0 40px 0;
}
.executive-committee {
  background: #e9e9f1;
  padding: 0 0 40px 0;
}
.executive-committee .col-xs-12 {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}
.executive-committee .col-xs-12 .card {
  background-color: #fff;
  padding: 14px 10px;
  display: flex;
  align-items: stretch;
  width: 49%;
  box-shadow: 3px 2px 12px #c7c7ce;
  margin-bottom: 20px;
  border-radius: 5px;
}
.executive-committee .col-xs-12 .card img {
  margin-right: 10px;
}
.executive-committee .col-xs-12 .card .img-wrapper {
  position: relative;
}
.executive-committee .col-xs-12 .card .img-wrapper img {
  width: 130px;
  min-width: 130px;
  height: 140px;
  max-height: 140px;
  object-fit: cover;
}
.executive-committee .col-xs-12 .card .img-wrapper img.flag {
  width: 33px;
  min-width: 33px;
  height: 22px;
  max-height: 22px;
  position: absolute;
  right: 10px;
  top: 0;
}
.executive-committee .col-xs-12 .card .img-wrapper .Flag-icon {
  position: absolute;
  right: 0;
  top: 0;
}
.executive-committee .col-xs-12 .card .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.executive-committee .col-xs-12 .card .info p {
  margin-bottom: 0;
}
.executive-committee .col-xs-12 .card .info .top p {
  font-family: 'futurabold';
  font-size: 18px;
  color: #182d70;
  line-height: 1.1;
  margin-bottom: 0;
}
.executive-committee .col-xs-12 .card .info .top span {
  font-size: 14px;
  color: #182d70;
  display: block;
  line-height: 1.1;
}
.executive-committee .col-xs-12 .card .info .top .person-name {
  display: inline;
}
.executive-committee .col-xs-12 .card .info .top .person-flag-wrapper {
  display: inline-block;
  margin-right: 7px;
  line-height: 18px;
  padding: 0;
  background: #e9e9f1;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
.executive-committee .col-xs-12 .card .info .top .person-flag {
  font-size: 16px;
  line-height: 0.8;
}
.executive-committee .col-xs-12 .card .info .sub {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.executive-committee .col-xs-12 .card .info .sub div p {
  font-size: 14px;
  color: #3c3c3d;
  line-height: 1;
  word-break: break-word;
}
.executive-committee .col-xs-12 .card .info .sub div p a {
  color: #3c3c3d;
  transition: 0.4s;
}
.executive-committee .col-xs-12 .card .info .sub div p a:hover {
  color: #182d70;
}
.executive-committee .col-xs-12 .card .info .sub > a {
  background: #364883;
  color: #fff;
  padding: 4px 6px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
  border: 1px solid #364883;
  transition: 0.4s;
  word-wrap: break-word;
}
.executive-committee .col-xs-12 .card .info .sub > a:hover {
  background: #fff;
  color: #182d70;
}
.executive-committee .col-xs-12 .card--block .info {
  padding: 20px 0 25px;
}
.executive-committee .col-xs-12 .card--block .info .middle {
  display: block;
}
.executive-committee .col-xs-12 .card--block .info .middle p {
  font-size: 14px;
  color: #182d70;
  display: block;
  line-height: 1.2;
  text-align: center;
}
.executive-committee .col-xs-12 .card--block-primary {
  border: 1px solid #a7a7a7;
  margin-bottom: 20px;
  background: #182d70 url(../img/BCK1.jpg) 50% 30%;
}
.executive-committee .col-xs-12 .card--block-primary .info .top {
  display: block;
}
.executive-committee .col-xs-12 .card--block-primary .info .top p {
  color: #fff;
  text-align: center;
  line-height: 1.2;
}
.executive-committee .col-xs-12 .card--block-primary .info .top span {
  text-align: center;
  color: #fff;
  line-height: 1.2;
}
.executive-committee .col-xs-12 .card--block-primary .info .middle p {
  color: #fff;
}
.executive-committee .col-xs-12 .card--block-primary .info .sub {
  display: block;
}
.executive-committee .col-xs-12 .card--block-primary .info .sub div p {
  color: #fff;
  font-family: 'futurabold';
  text-align: center;
  line-height: 1.2;
}
.executive-committee .col-xs-12 .card--block-primary .info .sub div p a,
.executive-committee .col-xs-12 .card--block-primary .info .sub div p a:hover {
  color: #fff;
}
.executive-committee .col-xs-12 .card--block-primary .info .sub > a {
  background: #fff;
  color: #364883;
  padding: 4px 6px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
  border: 1px solid #364883;
  transition: 0.4s;
  word-wrap: break-word;
}
.executive-committee .col-xs-12 .card--block-primary .info .sub > a:hover {
  background: #fff;
  color: #182d70;
}
.executive-committee .col-xs-12 .card--block-secondary {
  border: 1px solid #a7a7a7;
  margin-bottom: 20px;
  background: #e7e7e7;
  background: linear-gradient(to right, #d8dad9, #ededed);
}
.executive-committee .col-xs-12 .card--block-secondary .info .top {
  display: block;
}
.executive-committee .col-xs-12 .card--block-secondary .info .top p {
  text-align: center;
  line-height: 1.2;
}
.executive-committee .col-xs-12 .card--block-secondary .info .top span {
  text-align: center;
  line-height: 1.2;
}
.executive-committee .col-xs-12 .card--block-secondary .info .sub {
  display: block;
}
.executive-committee .col-xs-12 .card--block-secondary .info .sub div p {
  color: #182d70;
  font-family: 'futurabold';
  line-height: 1.2;
  text-align: center;
}
.executive-committee .col-xs-12 .card--block-secondary .info .sub div p a {
  color: #182d70;
}
.executive-committee .col-xs-12 .card--block-secondary .info .sub > a {
  background: #364883;
  color: #fff;
  padding: 4px 6px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
  border: 1px solid #364883;
  transition: 0.4s;
  word-wrap: break-word;
}
.executive-committee .col-xs-12 .card--block-secondary .info .sub > a:hover {
  background: #fff;
  color: #182d70;
}
.executive-committee--background-no {
  background: none;
}
.executive-committee.executive-committee--padding-no {
  padding: 0;
}
/* ==== Media Life Results ==== */
.athletes-rankings-table.media-res {
  padding-top: 40px;
}
.athletes-rankings-table.media-res .media-res-h {
  font-family: 'futurabold';
  color: #182d70;
  margin-bottom: 30px;
  line-height: 1;
  font-size: 34px;
}
.athletes-rankings-table.media-res .media-res-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.athletes-rankings-table.media-res .media-res-menu li {
  margin-right: 20px;
}
.athletes-rankings-table.media-res .media-res-menu li.active a {
  font-family: 'futurabold';
}
.athletes-rankings-table.media-res .media-res-menu li:last-child {
  margin-right: 0;
}
.athletes-rankings-table.media-res .media-res-menu li a {
  font-size: 18px;
  color: #182d70;
  transition: 0.4s;
}
.athletes-rankings-table.media-res .media-res-menu li a:hover {
  font-family: 'futurabold';
}
.media-res-link {
  display: block;
  background: url(../img/executive-committee-header-bg.jpg) no-repeat center;
  background-size: cover;
  padding: 40px 15px;
  text-align: center;
  color: #fff;
}
.media-res-link:focus,
.media-res-link:hover {
  color: #fff;
}
.media-res-link u {
  display: block;
  line-height: 1;
  text-decoration: none;
  font-size: 25px;
  margin-bottom: 20px;
}
.media-res-link span {
  display: block;
  line-height: 1;
  font-size: 75px;
}
.media-res-link span i {
  display: inline-block;
  font-size: 60px;
  color: #2c8ee0;
  margin-left: 10px;
}
.media-res-social {
  padding: 90px 0 85px 0;
  background: #e9e9f1;
}
.media-res-social .flex-top-parent {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 120px;
}
.media-res-social .flex-top-parent .card {
  display: block;
  background: #fff;
  padding: 18px 14px 14px 14px;
  text-align: center;
  border-radius: 5px;
  transition: transform 0.4s, box-shadow 0.4s, -webkit-transform 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.21);
}
.media-res-social .flex-top-parent .card:hover {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.45);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.media-res-social .flex-top-parent .card p {
  font-family: 'futurabold';
  font-size: 34px;
  line-height: 1;
  color: #182d70;
  margin-bottom: 10px;
}
.media-res-social .flex-top-parent .card .img-wrapper {
  display: block;
  width: 308px;
  height: 240px;
}
.media-res-social .flex-top-parent .card .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.media-res-social .flex-sub-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.media-res-social .flex-sub-parent a {
  display: block;
  width: 25%;
  transition: transform 0.4s, -webkit-transform 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.media-res-social .flex-sub-parent a:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.media-res-social .flex-sub-parent a img {
  width: 100%;
}
/* ==== Magazine ==== */
.athletes-rankings-table.media-res.magazine {
  padding-bottom: 0;
}
.magazin-flex-top {
  padding: 50px 0;
  background: #e9e9f1;
}
.magazin-flex-top .col-xs-12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.magazin-flex-top .col-xs-12 .left {
  max-width: 730px;
  margin-right: 60px;
}
.magazin-flex-top .col-xs-12 .left img {
  width: 100%;
}
.magazin-flex-top .col-xs-12 .right {
  padding-top: 30px;
}
.magazin-flex-top .col-xs-12 .right .number {
  font-size: 37px;
  color: #182d70;
  line-height: 1;
}
.magazin-flex-top .col-xs-12 .right .number span {
  font-family: 'futurabold';
}
.magazin-flex-top .col-xs-12 .right .txt {
  font-size: 37px;
  text-transform: uppercase;
  color: #182d70;
  margin: 28px 0 28px 0;
}
.magazin-flex-top .col-xs-12 .right .read {
  margin-bottom: 15px;
}
.magazin-flex-top .col-xs-12 .right .read a {
  display: inline-block;
  line-height: 47px;
  padding: 0 25px;
  min-width: 230px;
  border-radius: 5px;
  border: 1px solid #364883;
  background: #364883;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  transition: 0.4s;
}
.magazin-flex-top .col-xs-12 .right .read a:hover {
  background: transparent;
  color: #182d70;
}
.magazin-flex-top .col-xs-12 .right .signup a {
  display: inline-block;
  line-height: 47px;
  padding: 0 25px;
  min-width: 230px;
  border-radius: 5px;
  border: 1px solid #364883;
  background: transparent;
  color: #364883;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  transition: 0.4s;
}
.magazin-flex-top .col-xs-12 .right .signup a:hover {
  background: #364883;
  color: #fff;
}
.magazine-select {
  background: url(../img/executive-committee-header-bg.jpg) no-repeat center;
  padding: 45px 15px;
}
.magazine-select .slect-wrapper {
  padding: 10px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 465px;
  background: #fff;
  border-radius: 5px;
  color: #182d70;
  margin: 0 auto;
}
.magazine-select .slect-wrapper span {
  font-size: 18px;
}
.magazine-select .slect-wrapper select {
  max-width: 130px;
  width: 100%;
  height: 45px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #adadac;
  border-radius: 2px;
  font-size: 18px;
  color: #182d70;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../img/icons/icon-down.png) no-repeat 87%;
  background-color: #fff;
}
.magazine-cards {
  padding: 60px 0;
  background: #e9e9f1;
}
.magazine-cards .col-xs-12 {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}
.magazine-cards .col-xs-12 .card {
  width: 32.4%;
  background: #fff;
  padding: 14px;
  display: block;
  text-align: center;
  border-radius: 5px;
  transition: transform 0.4s, box-shadow 0.4s, -webkit-transform 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.21);
  margin-bottom: 15px;
}
.magazine-cards .col-xs-12 .card:hover {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.45);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.magazine-cards .col-xs-12 .card img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}
.magazine-cards .col-xs-12 .card .info {
  display: block;
  color: #182d70;
  padding: 20px 0 5px 0;
}
.magazine-cards .col-xs-12 .card .info u {
  text-transform: uppercase;
  text-decoration: none;
  color: #767676;
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1;
}
.magazine-cards .col-xs-12 .card .info em {
  font-style: normal;
  font-size: 22px;
  display: block;
}
.magazine-cards .col-xs-12 .card .info em b {
  font-family: 'futurabold';
}
.magazine-cards ul.pager {
  margin: 25px auto 0 auto;
}
.magazine-cards ul.pager li {
  font-size: 24px;
}
.magazine-cards ul.pager li a {
  border: none;
  color: #182d70;
  border-radius: 0;
  background: transparent;
}
.magazine-cards ul.pager li span {
  color: #182d70;
  border: none;
  font-family: 'futurabold';
  border-radius: 0;
  background: transparent;
}
/* ==== Photos ==== */
.magazine-cards.media-photos {
  padding-top: 20px;
}
.magazine-cards.media-photos .col-md-12.text-center p {
  font-family: 'futurabold';
  line-height: 1;
  margin-bottom: 24px;
  color: #182d70;
  font-size: 44px;
}
/* ==== Media ==== */
.media-tab {
  background: #e9e9f1;
}
.media-tab .nav.nav-tabs {
  margin-bottom: 80px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.media-tab .nav.nav-tabs li {
  float: none;
  margin: 3px;
  width: 100%;
}
.media-tab .nav.nav-tabs li a {
  background: transparent;
  border: 1px solid #182d70;
  border-radius: 5px;
  color: #182d70;
  font-size: 19px;
  display: block;
  width: 100%;
  float: none;
  transition: 0.4s;
  padding: 7px 5px;
  text-align: center;
}
.media-tab .nav.nav-tabs li a:hover {
  color: #fff;
  background: #182d70;
}
.media-tab .nav.nav-tabs li.active a {
  color: #fff;
  background: #182d70;
}
.media-tab .nav.nav-tabs.nav-no-margin {
  margin-bottom: 20px;
}
.media-tab .tab-content {
  min-height: 100px;
}
.media-tab .tab-content .heading {
  font-family: 'futurabold';
  font-size: 34px;
  color: #182d70;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 30px;
}
.media-tab .tab-content .flex-parent {
  margin-bottom: 80px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}
.media-tab .tab-content .flex-parent .card {
  width: 49%;
  margin-bottom: 20px;
  background: #fff;
  padding: 14px;
  border-radius: 5px;
  transition: transform 0.4s, box-shadow 0.4s, -webkit-transform 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.21);
  margin-bottom: 15px;
  color: #182d70;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.media-tab .tab-content .flex-parent .card:hover {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.45);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.media-tab .tab-content .flex-parent .card img {
  margin-right: 15px;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.media-tab .tab-content .flex-parent .card u {
  color: #182d70;
  display: block;
  text-decoration: none;
  font-size: 15px;
}
.media-tab .tab-content .flex-parent .card b {
  color: #182d70;
  font-family: 'futurabold';
  display: block;
  font-size: 18px;
  font-weight: normal;
  margin: 8px 0;
  line-height: 1;
}
.media-tab .tab-content .flex-parent .card span {
  display: block;
  font-size: 16px;
  color: #3c3c3d;
}
/* ==== Meetings ==== */
.meetings-wrapper {
  padding: 0 0 50px 0;
  background: #e9e9f1;
}
.meetings-wrapper .meetings-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.meetings-wrapper .meetings-menu li {
  margin-right: 22px;
}
.meetings-wrapper .meetings-menu li:last-child {
  margin-right: 0;
}
.meetings-wrapper .meetings-menu li a {
  color: #182d70;
  font-size: 27px;
  display: inline-block;
  border-bottom: 2px solid transparent;
  transition: 0.4s;
}
.meetings-wrapper .meetings-menu li a:hover {
  color: #2c8ee0;
}
.meetings-wrapper .meetings-menu li.active a {
  font-family: 'futurabold';
  border-color: #2c8ee0;
}
.athletes-rankings-table.media-res.meetings-table {
  padding: 0 0 76px 0;
}
.athletes-rankings-table.media-res.meetings-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(3),
.athletes-rankings-table.media-res.meetings-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(3) {
  text-align: center;
  padding-left: 10px;
}
.athletes-rankings-table.media-res.meetings-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(1),
.athletes-rankings-table.media-res.meetings-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(1) {
  text-align: left;
  padding-left: 20px;
}
.grandPrixHero-img {
  width: 100%;
}
@media (max-width: 768px) {
  .CompetitionsTable--grandprix.table td {
    display: none;
  }
  .CompetitionsTable--grandprix.table .Table-cell--hide-md {
    display: table-cell;
  }
}
.statistics-table .table,
.grand-prix-table .table {
  margin-bottom: 0;
}
.statistics-table .table tr th,
.grand-prix-table .table tr th {
  font-size: 18px;
}
.statistics-table .table .Table-row,
.grand-prix-table .table .Table-row {
  display: table-row;
}
.statistics-table .table tr th:nth-child(3),
.grand-prix-table .table tr th:nth-child(3),
.statistics-table .table tr td:nth-child(3),
.grand-prix-table .table tr td:nth-child(3),
.statistics-table .table a td:nth-child(3),
.grand-prix-table .table a td:nth-child(3) {
  text-align: center;
}
.statistics-table .table tr th:nth-child(3) p,
.grand-prix-table .table tr th:nth-child(3) p,
.statistics-table .table tr td:nth-child(3) p,
.grand-prix-table .table tr td:nth-child(3) p,
.statistics-table .table a td:nth-child(3) p,
.grand-prix-table .table a td:nth-child(3) p {
  white-space: nowrap;
}
.statistics-table .table tr th:nth-child(1),
.grand-prix-table .table tr th:nth-child(1),
.statistics-table .table tr td:nth-child(1),
.grand-prix-table .table tr td:nth-child(1),
.statistics-table .table a td:nth-child(1),
.grand-prix-table .table a td:nth-child(1) {
  text-align: left;
}
.statistics-table .table tr td,
.grand-prix-table .table tr td,
.statistics-table .table a td,
.grand-prix-table .table a td {
  font-size: 18px;
  background: #fff;
  border: 1px solid #efefef;
  color: #182d70;
  padding: 8px;
  line-height: 1.4;
  text-align: center;
  padding-top: 15px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.statistics-table .table tr:nth-child(odd) td,
.grand-prix-table .table tr:nth-child(odd) td {
  background: #efefef;
}
.statistics-table .table tr:hover td,
.grand-prix-table .table tr:hover td {
  background: #dedbdb;
  cursor: pointer;
}
.statistics-table .table tr td p,
.grand-prix-table .table tr td p,
.statistics-table .table a td p,
.grand-prix-table .table a td p {
  line-height: 1;
}
.statistics-table .table tr td p.sm,
.grand-prix-table .table tr td p.sm,
.statistics-table .table a td p.sm,
.grand-prix-table .table a td p.sm {
  font-size: 16px;
}
.statistics-table .table tr td:first-child p,
.grand-prix-table .table tr td:first-child p,
.statistics-table .table a td:first-child p,
.grand-prix-table .table a td:first-child p {
  display: flex;
  align-items: center;
}
.statistics-table .table tr td:first-child p i,
.grand-prix-table .table tr td:first-child p i,
.statistics-table .table a td:first-child p i,
.grand-prix-table .table a td:first-child p i {
  display: block;
  margin-right: 8px;
}
.statistics-table .table tr td img,
.grand-prix-table .table tr td img,
.statistics-table .table a td img,
.grand-prix-table .table a td img {
  border-radius: 0;
  vertical-align: 0;
}
/* ==== Results Competitions ==== */
.athletes-rankings.results-competitions .top-row .Search-dropdown {
  font-family: inherit;
  background-color: #fff;
  border-radius: 2px;
  border-color: #adadac;
  color: #182d70;
  height: 46px;
  padding: 0 14px;
  font-size: 18px;
  margin-right: 15px;
}
.athletes-rankings.results-competitions .select-row .Search-dropdown {
  font-family: inherit;
}
.athletes-rankings.results-competitions .select-row input.Search-dropdown {
  background-color: #fff;
  border-radius: 2px;
  border-color: #adadac;
  color: #182d70;
  height: 50px;
  font-size: 18px;
}
@media (max-width: 768px) {
  .athletes-rankings.results-competitions .select-row input.Search-dropdown {
    width: 100%;
  }
}
.athletes-rankings.results-competitions .flex-parent .links {
  color: #fff;
  overflow: hidden;
}
.athletes-rankings.results-competitions .flex-parent .links label {
  line-height: 1.2;
  padding: 0;
  background: none;
  color: #c6c4c4;
  margin: 0 3px;
  font-size: 24px;
  transition: 0.4s;
  font-weight: 100;
  cursor: pointer;
}
.athletes-rankings.results-competitions .flex-parent .links label:hover {
  color: #fff;
}
.athletes-rankings.results-competitions .flex-parent .links label.active {
  font-family: 'futurabold';
  margin: 0 3px;
  color: #fff;
}
.athletes-rankings.results-competitions .flex-parent .links input[type="radio"] {
  width: 1px;
  height: 1px;
  visibility: hidden;
  position: absolute;
  left: -999px;
  top: -999px;
  z-index: -1000;
}
.athletes-rankings.results-competitions .flex-parent .links input[type="radio"]:checked + label {
  font-family: 'futurabold';
  margin: 0 3px;
  color: #fff;
}
.athletes-rankings.results-competitions .filter-wrapper .sub-row .select-row {
  justify-content: space-between;
}
.athletes-rankings.results-competitions .filter-wrapper .sub-row .select-row select {
  margin-right: 0;
  width: 100%;
  padding: 10px 15px;
  margin-right: 15px;
}
.athletes-rankings.results-competitions .filter-wrapper .sub-row .select-row select:last-child {
  margin-right: 0;
}
.athletes-rankings.results-competitions .filter-wrapper .top-row .check-container [type=submit] {
  background: #364883;
  color: #fff;
}
.athletes-rankings.results-competitions .filter-wrapper {
  margin-bottom: 30px;
}
.athletes-rankings.results-competitions .calendar-links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.athletes-rankings.results-competitions .calendar-links a {
  display: inline-block;
  background: #e8eef5;
  color: #182d70;
  text-align: center;
  line-height: 54px;
  border-radius: 5px;
  padding: 0 25px;
  min-width: 280px;
  font-size: 19px;
  transition: 0.4s;
}
.athletes-rankings.results-competitions .calendar-links a:hover {
  color: #fff;
  background: #364883;
}
.athletes-rankings-table.results-competitions-table .table {
  border-collapse: separate;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr th {
  font-size: 18px;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table .Table-row {
  display: table-row;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(3),
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(3),
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(3) {
  padding-left: 10px;
  text-align: center;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(3) p,
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(3) p,
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(3) p {
  white-space: nowrap;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(1),
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(1),
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(1) {
  padding-left: 20px;
  text-align: left;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td,
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td {
  font-size: 18px;
  background: #fff;
  border: 1px solid #efefef;
  border-color: #efefef !important;
  color: #182d70;
  padding: 8px;
  line-height: 1.4;
  text-align: center;
  padding-top: 15px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(1),
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(1),
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(2),
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(2) {
  font-size: 22px;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a.CompetitionsTable-row-subCompetition td:nth-child(1) {
  padding-left: 40px;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a:nth-child(odd) td {
  background: #efefef;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a.CompetitionsTable-row-subCompetition td {
  background: #e4e2e2;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a:hover td {
  background: #dedbdb;
  cursor: pointer;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td p,
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td p {
  line-height: 1;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td p.sm,
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td p.sm {
  font-size: 16px;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:first-child p,
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:first-child p {
  display: flex;
  align-items: center;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:first-child p i,
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:first-child p i {
  display: block;
  margin-right: 8px;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td img,
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td img {
  border-radius: 0;
  vertical-align: 0;
}
.athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(n+4) {
  padding-top: 20px;
}
/* ==== Wach ==== */
.wach-main-player {
  background: url(../img/Athletes_ranking_bg.jpg) no-repeat center;
  background-size: cover;
  padding-bottom: 40px;
}
.wach-main-player .wach-h {
  color: #fff;
  font-size: 33px;
  text-align: center;
  font-family: 'futurabold';
  line-height: 1;
  margin-bottom: 10px;
}
.wach-main-player .wach-h br {
  display: none;
}
.wach-main-player .wach-date {
  font-size: 17px;
  line-height: 1;
  color: #cfcfd2;
  text-align: center;
  margin-bottom: 20px;
}
.wach-main-player .wach-player-wrapper {
  max-width: 750px;
  background: #fff;
  padding: 15px 15px 30px 15px;
  border-radius: 5px;
  margin: 0 auto;
}
.wach-main-player .wach-player-wrapper .frame-wr {
  width: 100%;
  height: 400px;
}
.wach-main-player .wach-player-wrapper .frame-wr iframe {
  border: none!important;
  width: 100%!important;
  height: 100%!important;
}
.wach-main-player .wach-player-wrapper .wach-title {
  font-size: 22px;
  color: #2e2f2f;
  margin-top: 20px;
  margin-bottom: 30px;
  line-height: 1.1;
}
.wach-main-player .wach-player-wrapper .wach-title span {
  display: none;
}
.wach-main-player .wach-player-wrapper .wach-link {
  font-size: 22px;
  color: #2e2f2f;
  line-height: 1.1;
}
.wach-sub-filter {
  padding: 40px 0;
  background: #e9e9f1;
}
.wach-sub-filter .serach-f-wrapper {
  padding: 15px 20px;
  background: #dbdbdb;
  position: relative;
  max-width: 1025px;
  margin: 0 auto 20px auto;
  border-radius: 5px;
  overflow: hidden;
}
.wach-sub-filter .serach-f-wrapper input {
  line-height: 27px;
  padding-left: 37px;
  border: none;
  font-size: 24px;
  width: 100%;
  background: url(../img/icons/zoom.png) no-repeat left;
  color: #182d70;
}
.wach-sub-filter .serach-f-wrapper input::-webkit-input-placeholder {
  color: #182d70;
}
.wach-sub-filter .serach-f-wrapper input::-moz-placeholder {
  color: #182d70;
}
.wach-sub-filter .serach-f-wrapper input:-ms-input-placeholder {
  color: #182d70;
}
.wach-sub-filter .serach-f-wrapper input:-moz-placeholder {
  color: #182d70;
}
.wach-sub-filter .serach-f-wrapper input[type="submit"] {
  position: absolute;
  z-index: 5;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  padding: 0 15px;
  min-width: 110px;
  background: #364883;
  border: none;
  height: 42px;
  top: 8.5px;
  right: 20px;
  display: none;
  width: auto;
}
.wach-sub-filter .serach-f-wrapper input[type="submit"].active {
  display: block;
  animation: zoomIn 0.3s 1;
}
.wach-sub-filter .wach-cards {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1025px;
  margin: 0 auto;
}
.wach-sub-filter .wach-cards .card {
  width: 32%;
  margin-bottom: 20px;
  background: #fff;
  transition: transform 0.4s, box-shadow 0.4s, -webkit-transform 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.21);
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.wach-sub-filter .wach-cards .card:hover {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.45);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.wach-sub-filter .wach-cards .card .card-h {
  padding: 10px 15px;
  color: #fff;
  text-align: center;
  background: #182d70;
  line-height: 1.1;
  font-size: 22px;
}
.wach-sub-filter .wach-cards .card .img-wrapper {
  width: 100%;
  height: 200px;
  position: relative;
}
.wach-sub-filter .wach-cards .card .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wach-sub-filter .wach-cards .card .img-wrapper > span {
  display: inline-block;
  position: absolute;
  color: #fff;
  font-size: 16px;
  font-family: 'futurabold';
  bottom: 0;
  right: 0;
  background: #9195ba;
  line-height: 1;
  padding: 5px 10px 3px 10px;
}
.wach-sub-filter .wach-cards .card .card-info {
  padding: 10px 15px;
  font-size: 20px;
  color: #182d70;
}
.wach-sub-filter .wach-cards .card .card-info .card-date {
  margin-bottom: 5px;
  color: #808080;
}
.wach-sub-filter ul.pager {
  margin: 20px auto 30px auto;
}
.wach-sub-filter ul.pager li {
  font-size: 24px;
}
.wach-sub-filter ul.pager li a {
  border: none;
  color: #182d70;
  border-radius: 0;
  background: transparent;
}
.wach-sub-filter ul.pager li span {
  color: #182d70;
  border: none;
  font-family: 'futurabold';
  background: transparent;
}
/* ==== SAFE SPORT ==== */
.safe-sport-header {
  padding-top: 45px;
  padding-bottom: 35px;
  background: url(../img/Athletes_ranking_bg.jpg) no-repeat center top / cover;
  color: #fff;
}
.safe-sport-header .safe-sport-header-h {
  line-height: 1;
  text-align: center;
  font-family: 'futurabold';
  font-size: 44px;
  margin-bottom: 65px;
}
.safe-sport-header span {
  font-family: 'futurapt';
  display: block;
  font-size: 21px;
  margin-bottom: 25px;
  line-height: 1.2;
}
.safe-sport {
  background: #e9e9f1;
  padding-bottom: 100px;
}
.safe-sport .top-links {
  color: #182d70;
}
.safe-sport .top-links p {
  font-size: 34px;
  line-height: 1;
  font-family: 'futurabold';
  margin-bottom: 5px;
}
.safe-sport .top-links span {
  display: block;
  font-size: 17.2px;
  margin-bottom: 30px;
  line-height: 1.3;
}
.safe-sport .top-links span a {
  color: #182d70;
  font-family: 'futurabold';
  border-bottom: 1px solid #182d70;
  transition: 0.4s all;
}
.safe-sport .top-links span a:hover {
  border-color: transparent;
}
.safe-sport .top-links span:last-child {
  margin-bottom: 10px;
}
.safe-sport .form-tile {
  background: #fff;
  border-radius: 5px;
  box-shadow: 6px 6px 15px rgba(190, 190, 197, 0.45);
  padding: 38px;
  color: #182d70;
  margin-bottom: 50px;
}
.safe-sport .form-tile .tile-h {
  font-family: 'futurabold';
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.safe-sport .form-tile .inf {
  font-size: 23px;
  font-family: 'futurapt';
  display: block;
  line-height: 1.2;
}
.safe-sport .form-tile .inf.inf1 {
  margin-bottom: 48px;
}
.safe-sport .form-tile .inf.inf2 {
  margin-bottom: 25px;
}
.safe-sport .form-tile .inf.inf3 {
  font-size: 18px;
  margin-bottom: 38px;
}
.safe-sport .form-tile label.radio {
  display: inline-block;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 14px;
  margin-top: 0;
  cursor: pointer;
  color: #182d70;
}
.safe-sport .form-tile label.radio:last-child {
  margin-bottom: 0;
}
.safe-sport .form-tile label.radio input[type="radio"] {
  display: none;
}
.safe-sport .form-tile label.radio input[type="radio"] + i {
  border-radius: 50%;
}
.safe-sport .form-tile label.radio i {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #182d70;
  position: relative;
  margin-right: 15px;
}
.safe-sport .form-tile label.radio i:before {
  content: "";
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background: #182d70;
  border-radius: 50%;
  transform: scale(0);
  transition: 200ms;
}
.safe-sport .form-tile label.radio input:checked + i:before {
  transform: scale(1);
}
.safe-sport .form-tile label.checkbox {
  display: flex;
  align-items: flex-start;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 14px;
  margin-top: 0;
  cursor: pointer;
  color: #182d70;
  line-height: 1.2;
}
.safe-sport .form-tile label.checkbox:last-child {
  margin-bottom: 0;
}
.safe-sport .form-tile label.checkbox span {
  display: inline-block;
  line-height: 1.2;
}
.safe-sport .form-tile label.checkbox span u {
  text-decoration: none;
  color: #fe0000;
  font-size: 18px;
}
.safe-sport .form-tile label.checkbox span a {
  color: #182d70;
  border-bottom: 1px solid #182d70;
  transition: 0.4s all;
  font-family: 'futurabold';
  font-size: 18px;
}
.safe-sport .form-tile label.checkbox span a:hover {
  border-color: transparent;
}
.safe-sport .form-tile label.checkbox input[type="checkbox"] {
  display: none;
}
.safe-sport .form-tile label.checkbox input[type="checkbox"] + i {
  border-radius: 2px;
}
.safe-sport .form-tile label.checkbox i {
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid #182d70;
  position: relative;
  margin-right: 13px;
  margin-top: 4px;
}
.safe-sport .form-tile label.checkbox i:before {
  content: "";
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background: url(../img/icons/check2.png) no-repeat center center / contain;
  border-radius: 0;
  transform: scale(0);
  transition: 200ms;
}
.safe-sport .form-tile label.checkbox input:checked + i:before {
  transform: scale(1);
}
.safe-sport .form-tile label.form-group {
  display: block;
  margin-bottom: 25px;
  font-weight: normal;
  margin-top: 0;
}
.safe-sport .form-tile label.form-group:last-child {
  margin-bottom: 0;
}
.safe-sport .form-tile label.form-group span {
  display: block;
  font-size: 18px;
  font-family: 'futurapt';
  margin-bottom: 5px;
}
.safe-sport .form-tile label.form-group span small {
  margin: 0;
  padding: 0;
  font-size: 16px;
  display: block;
  color: #575858;
  line-height: 1;
  margin-bottom: 8px;
}
.safe-sport .form-tile label.form-group span u {
  text-decoration: none;
  color: #fe0000;
}
.safe-sport .form-tile label.form-group input {
  line-height: 48px;
  height: 50px;
  border-radius: 2px;
  border: 1px solid #adadac;
  display: block;
  width: 100%;
  padding-left: 18px;
  font-size: 18px;
  color: #575858;
}
.safe-sport .form-tile label.form-group select {
  text-transform: uppercase;
  height: 50px;
  border-radius: 2px;
  border: 1px solid #adadac;
  display: block;
  width: 100%;
  padding-left: 18px;
  font-size: 18px;
  color: #575858;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../img/icons/icon-down.png) no-repeat 97%;
  padding-right: 35px;
}
.safe-sport .form-tile label.form-group select.fn option:first-child {
  display: none;
}
.safe-sport .form-tile label.form-group textarea {
  resize: none;
  height: 100px;
  border-radius: 2px;
  border: 1px solid #adadac;
  display: block;
  width: 100%;
  padding: 12px  12px 12px 18px;
  font-size: 18px;
  color: #575858;
}
.safe-sport .form-tile .recaptcha {
  margin-top: 20px;
}
.safe-sport .next-block p {
  font-size: 16px;
  color: #717171;
  margin-bottom: 40px;
}
.safe-sport .next-block button {
  font-size: 22px;
  color: #fff;
  border: 1px solid #364883;
  background: #364883;
  padding: 0 20px;
  border-radius: 5px;
  text-transform: uppercase;
  height: 60px;
  min-width: 140px;
  transition: 0.4s all;
}
.safe-sport .next-block button:hover {
  background: #fff;
  color: #364883;
}
/** Removed clean-sport class: .Document--clean-sport
 * added common class fie-documents for all documents
 */
.Fie-documents {
  background: #e9e9f1;
  padding-top: 0;
}
.Fie-documents hr {
  display: none;
}
.Fie-documents .Form-dropdown {
  max-width: 300px;
  width: 100%;
  height: 50px;
  padding-left: 30px;
  padding-right: 35px;
  border: 1px solid #adadac;
  border-radius: 2px;
  font-size: 18px;
  color: #182d70;
  font-family: inherit;
  background-color: #fff;
  margin: 0;
}
.Fie-documents .Document-content-subheader {
  font-family: futurabold;
  font-size: 24px;
  color: #182d70;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.Fie-documents .Document-header {
  margin-bottom: 0;
}
.Fie-documents .Document-content-inner ul {
  margin-bottom: 60px;
}
@media (max-width: 767.98px) {
  .Fie-documents .Document-content-sidebar ul {
    padding-inline-start: 0px;
  }
}
.Fie-documents .Document-content-sidebar li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.Fie-documents .Document-content-sidebar li a {
  color: #182d70;
  display: inline-block;
  line-height: 1.1;
  border-bottom: 1px solid #c9c9cf;
  display: block;
  transition: 0.4s;
  font-family: inherit;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .Fie-documents .Document-content-sidebar li a {
    color: #fff;
    font-size: 14px;
    padding: 15px 20px;
  }
}
@media (min-width: 767.98px) {
  .Fie-documents .Document-content-sidebar li a {
    font-size: 20px;
    padding: 20px 0;
  }
}
.Fie-documents .Document-content-sidebar li a:hover {
  color: #2c8ee0;
}
@media (min-width: 767.98px) {
  .Fie-documents .Document-content-sidebar li:first-child a {
    font-family: futurabold;
    font-size: 20px;
    color: #182d70;
    padding-top: 0;
  }
}
@media (max-width: 767.98px) {
  .Fie-documents .Document-content-sidebar li:first-child a {
    font-family: futurabold;
    font-size: 14px;
    color: #fff;
    padding-top: 0px;
  }
}
.Fie-documents .Document-content-sidebar li:last-child a {
  border-bottom: none;
}
.Fie-documents .Document-content-sidebar li.active a {
  font-family: futurabold;
}
@media (min-width: 760px) {
  .Fie-documents .Document-content-sidebar {
    margin-left: 0;
    flex-basis: 35%;
  }
}
.Fie-documents .Document-externalDocument {
  color: #182d70;
  font-size: 17px;
  padding: 15px;
  background: #e0e0ec;
  margin-bottom: 12px;
  transition: box-shadow 0.2s, background-color 0.2s;
  border-radius: 0;
}
.Fie-documents .Document-externalDocument span {
  color: inherit !important;
}
.Fie-documents .Document-externalDocument:hover {
  background: #fff;
}
/* ==== MEDIA ==== */
@media (max-width: 1210px) {
  header .primary-menu .menu li a {
    font-size: 16px;
  }
  header .primary-menu .menu li {
    margin: 0 4px;
  }
  .partners .left li {
    margin-right: 25px;
  }
  .top-carousel .owl-carousel .item img {
    min-height: auto;
  }
  .partners .right {
    margin-left: auto;
  }
  .news .col-xs-12 .news-col {
    width: 74%;
    min-width: 74%;
  }
  .news .col-xs-12 .projects-col {
    width: 24%;
    min-width: 24%;
  }
  .baners-bot .wrap {
    width: 100%;
    min-width: 300px;
  }
}
@media (min-width: 1200px) {
  .container.small {
    width: 1060px;
  }
}
@media (min-width: 992px) {
  .executive-committee-header .col-xs-12 .info {
    border-top: 1px solid white;
    padding-top: 20px;
  }
  .executive-committee-header--small .col-xs-12 .info {
    border-top: none;
  }
}
@media (max-width: 1199px) {
  .news .col-xs-12 .news-col a.large .info {
    padding: 10px 25px 24px 25px;
  }
  .news .col-xs-12 .news-col a.small .img-wrapper {
    margin-right: 0;
    width: 200px;
    min-width: 200px;
    height: auto;
  }
  .news .col-xs-12 .news-col a.small .img-wrapper img {
    height: 100%;
  }
  .news .col-xs-12 .news-col a.small .info {
    padding: 18px;
    background: #fff;
  }
  .top-fancers .flex-parent .card {
    align-items: center;
  }
  .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(2) {
    min-width: 90px;
  }
  .news .col-xs-12 .news-col a.small {
    background: #fff;
    align-items: stretch;
  }
  .news .col-xs-12 .news-col a.small .info .description {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .news .col-xs-12 .news-col a.small .info .headig {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .news .col-xs-12 .news-col a.small .info .xs-text {
    font-size: 15px;
  }
  .partners {
    margin: -20px 0 0;
    flex-direction: column-reverse;
    background: #c3c3c3;
    padding: 10px 0 0 0 ;
  }
  .partners .right {
    margin-right: auto;
    margin-bottom: 10px;
  }
  .partners .left li {
    margin-right: 0;
  }
  .partners .left li:nth-child(2) {
    margin: 0 25px;
  }
  .partners .left {
    width: 100%;
    padding: 10px;
  }
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label {
    margin-right: 20px;
  }
  .athletes-rankings .flex-cards .card {
    width: 33%;
  }
  .athletes-rankings .flex-cards .card img {
    width: 100%;
  }
  .executive-committee .col-xs-12 .card img {
    width: 137px;
    min-width: 137px;
    height: 153px;
  }
  .executive-committee .col-xs-12 .card .info .sub div p {
    font-size: 14px;
  }
  .executive-committee .col-xs-12 .card .info .top p {
    font-size: 23px;
  }
  .executive-committee .col-xs-12 .card .info .top span {
    font-size: 18px;
  }
  .media-res-social .flex-top-parent .card .img-wrapper {
    width: 100%;
    height: auto;
  }
  .media-res-social .flex-top-parent .card {
    width: 32.5%;
  }
  .magazine-cards .col-xs-12 .card img {
    height: 210px;
  }
  .magazine-cards .col-xs-12 .card .info em {
    font-size: 20px;
  }
}
@media (max-width: 1010px) {
  .follow-us {
    display: block;
    background: none;
    border: none;
    color: #182d70;
    padding-right: 15px;
    background: url(../img/icons/plus-solid.svg) no-repeat right;
    background-size: 11px;
  }
  .follow-us.active {
    color: transparent;
    width: 20px;
    height: 20px;
    padding: 0;
    width: 81px;
    background: url(../img/icons/times-solid.svg) no-repeat right;
    background-size: 15px;
    padding-bottom: 28px;
  }
  header {
    height: auto;
  }
  header .primary-menu {
    padding: 0 10px;
    position: relative;
    top: 0;
  }
  header .primary-menu .socials {
    position: absolute;
    z-index: 4;
    width: 100%;
    left: 0;
    bottom: -60px;
    justify-content: space-around;
    background: #ffffff;
    padding: 15px 185px 10px 185px;
    display: none;
  }
  header .primary-menu .socials.active {
    display: flex;
  }
  header .primary-menu a.logo {
    min-width: 120px;
    width: 120px;
  }
  header .tools {
    margin-bottom: 0;
    max-width: 450px;
    padding-left: 15px;
    padding-right: 15px;
  }
  header .tools li a {
    font-size: 14px;
  }
  header .primary-menu a.logo {
    min-width: 95px;
    width: 95px;
  }
  header .primary-menu .menu li {
    margin: 0;
  }
  header .primary-menu .menu li a {
    font-size: 16px;
    padding: 4px 6px;
  }
  body {
    padding-top: 86px;
  }
  header.active .primary-menu {
    padding-top: 0;
  }
}
@media (max-width: 991px) {
  .news .col-xs-12 .news-col {
    width: 65%;
    min-width: 65%;
  }
  .news .col-xs-12 .projects-col {
    width: 33%;
    min-width: 33%;
  }
  .news .col-xs-12 .news-col a.large .info .headig {
    font-size: 19px;
  }
  .news .col-xs-12 .news-col a.large .info .description {
    font-size: 24px;
  }
  .news .col-xs-12 .news-col a.large .info .xs-text {
    font-size: 18px;
  }
  .news .col-xs-12 .projects-col .card span {
    font-size: 15px;
  }
  .statistics-table .col-xs-12 {
    flex-direction: column;
  }
  .statistics-table .col-xs-12 .table-parent {
    width: 100%;
    margin-bottom: 15px;
  }
  .video-player .flex-parent {
    flex-direction: column;
  }
  .video-player .flex-parent .video {
    width: 100%;
  }
  .video-player .flex-parent .video-list {
    padding-top: 0;
    width: 100%;
  }
  .video-player .olympic {
    display: none;
  }
  .top-fancers .col-xs-12 {
    padding: 0 15px;
  }
  .social-wall .social-share .card span.info {
    font-size: 16px;
    line-height: 1.1;
  }
  .social-wall .social-share .card.facebook .soc {
    padding-left: 5px;
  }
  .social-wall .social-share .card span.soc i {
    font-size: 15px;
  }
  a.section-link span {
    font-size: 48px;
  }
  a.section-link span i {
    font-size: 35px;
  }
  footer .container .col-xs-12 ul li {
    margin-right: 20px;
  }
  footer .container .col-xs-12 ul li a {
    font-size: 19px;
  }
  footer .container .col-xs-12 .copy {
    font-size: 15px;
  }
  footer .container .col-xs-12 .copy br {
    display: none;
  }
  .news .col-xs-12 .news-h,
  .news .col-xs-12 .news-h {
    font-size: 34px;
  }
  .video-player .player-h {
    font-size: 46px;
    background-position: left 6px;
  }
  .social-wall .wall-h {
    font-size: 38px;
  }
  .top-fancers {
    padding-top: 35px;
    min-height: 100%;
  }
  .top-fancers .flex-parent {
    overflow: hidden;
    margin-bottom: 0;
    padding-bottom: 30px;
    background: #fff;
  }
  .top-fancers .flex-parent:last-child {
    border-radius: 0 0 5px 5px;
  }
  .top-fancers .flex-parent .card {
    width: 50%;
    padding: 5px 5px 5px 20px;
    border-radius: 0;
  }
  .top-fancers .flex-parent .card .img-wrapper {
    width: 50px;
    min-width: 50px;
    height: auto;
  }
  .top-fancers .flex-parent .card .info .name {
    font-size: 13px;
  }
  .top-fancers .flex-parent .card .info .description {
    display: none;
  }
  .top-fancers .flex-parent .card .info > u {
    font-size: 12px;
  }
  .top-fancers .flex-parent .card .info > u i {
    display: none;
  }
  .top-fancers .subtitle {
    margin-bottom: 0;
    font-size: 14px;
    background: #fff;
    text-align: left;
    padding-left: 5px;
    color: #182d70;
    padding-top: 5px;
  }
  .top-fancers .subtitle:nth-child(2) {
    border-radius: 5px 5px 0 0;
  }
  .baners-bot .baners-bot__item {
    max-width: 100%;
    width: 50%;
    margin-bottom: 25px;
  }
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators {
    padding-left: 9px;
  }
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label {
    margin-right: 9px;
  }
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label input[type=checkbox] + i,
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label input[type=radio] + i {
    margin-right: 5px;
  }
  .athletes-rankings .flex-cards {
    flex-wrap: wrap;
    justify-content: center;
  }
  .athletes-rankings .flex-cards .card {
    width: 48%;
    margin: 5px;
  }
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr td,
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th {
    font-size: 22px;
  }
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .text-center ul.pager li {
    font-size: 20px;
  }
  .championnats-asiatiques .date {
    font-size: 28px;
  }
  .championnats-asiatiques .championnats-asiatiques-h {
    font-size: 58px;
  }
  .championnats-asiatiques .flex-parent > p {
    font-size: 19px;
  }
  .championnats-asiatiques .flex-parent {
    flex-wrap: wrap;
  }
  .championnats-asiatiques .flex-parent > p {
    min-width: 49%;
    margin-bottom: 8px;
  }
  .championnats-asiatiques .overview .flex-overview {
    flex-wrap: wrap;
  }
  .championnats-asiatiques .overview .flex-overview .right {
    margin-top: 25px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
  .championnats-asiatiques .overview .flex-overview .right a {
    width: 49%;
  }
  .championnats-asiatiques .overview .flex-overview .right a:last-child {
    margin-bottom: 14px;
  }
  .championnats-asiatiques .flex-parent {
    margin-bottom: 30px;
  }
  .clean-sport-flex-parent .col-xs-12 .left {
    padding-right: 30px;
  }
  .executive-committee-header .col-xs-12 .info span {
    font-size: 19px;
  }
  .executive-committee-header .col-xs-12 .info h1 {
    font-size: 38px;
  }
  .executive-committee-header .col-xs-12 .info p,
  .executive-committee-header .col-xs-12 .info .sub_text {
    font-size: 19px;
  }
  .executive-committee-header .col-xs-12 .list-menu a.heading,
  .executive-committee-header .col-xs-12 .list-menu ul li a {
    font-size: 18px;
  }
  .executive-committee .col-xs-12 .card {
    width: 100%;
  }
  .media-res-social .flex-top-parent .card p {
    font-size: 28px;
  }
  .media-res-social {
    padding: 40px 0 45px 0;
  }
  .media-res-social .flex-top-parent {
    margin-bottom: 70px;
  }
  .media-res-link span {
    font-size: 65px;
  }
  .media-res-link span i {
    font-size: 50px;
  }
  .athletes-rankings-table.media-res {
    padding-bottom: 45px;
  }
  .magazin-flex-top .col-xs-12 .left {
    margin-right: 30px;
  }
  .magazine-cards .col-xs-12 .card {
    width: 49%;
  }
  .magazine-cards .col-xs-12 .card img {
    height: 250px;
  }
  .media-tab .tab-content .flex-parent .card b {
    font-size: 16px;
  }
  .media-tab .tab-content .flex-parent .card span {
    font-size: 14px;
  }
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(n+7),
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(n+7) {
    display: none;
  }
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(6) {
    border-top-right-radius: 8px;
  }
  .wach-main-player .wach-player-wrapper .frame-wr {
    height: 440px;
  }
  .wach-sub-filter .wach-cards .card .card-h br {
    display: none;
  }
  .wach-sub-filter .wach-cards .card .card-h {
    padding: 10px;
  }
  .wach-sub-filter .wach-cards .card .card-h {
    font-size: 15px;
  }
  .wach-sub-filter .wach-cards .card .card-info {
    font-size: 15px;
  }
  .wach-sub-filter .wach-cards .card .img-wrapper > span {
    font-size: 14px;
  }
  .wach-sub-filter .wach-cards .card .img-wrapper {
    height: 160px;
  }
  .wach-main-player .wach-h {
    font-size: 30px;
  }
  .wach-main-player .wach-player-wrapper .wach-title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .wach-main-player .wach-player-wrapper .wach-link {
    font-size: 18px;
  }
  .safe-sport .form-tile {
    padding: 25px;
  }
  .safe-sport .form-tile .tile-h {
    font-size: 21px;
  }
  .safe-sport .form-tile label.form-group input {
    line-height: 40px;
    height: 42px;
  }
  .safe-sport .form-tile label.form-group select {
    height: 42px;
  }
  .safe-sport .form-tile label.form-group textarea {
    height: 90px;
  }
  .safe-sport .form-tile label.checkbox span br {
    display: none;
  }
  .safe-sport .form-tile label.form-group {
    margin-bottom: 15px;
  }
  .safe-sport .form-tile .inf.inf2,
  .safe-sport .form-tile .inf.inf1 {
    font-size: 19px;
    margin-bottom: 30px;
  }
  .safe-sport .form-tile label.radio {
    font-size: 19px;
  }
  .safe-sport .form-tile {
    margin-bottom: 35px;
  }
  .safe-sport .form-tile label.checkbox span {
    font-size: 19px;
  }
  .safe-sport .form-tile label.checkbox span a {
    font-size: 15px;
  }
  .safe-sport .top-links p {
    font-size: 28px;
  }
  .safe-sport .top-links span {
    margin-bottom: 20px;
  }
  .safe-sport .top-links span br {
    display: none;
  }
}
@media (max-width: 760px) {
  header {
    display: none !important;
  }
  body {
    padding-top: 50px;
  }
  .xs-menu {
    display: block;
    position: fixed;
    z-index: 14;
    width: 100%;
    top: 0;
    left: 0;
  }
  .xs-menu .top {
    height: 50px;
    background: #2A226F;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .xs-menu .top > a {
    display: block;
    height: 42px;
  }
  .xs-menu .top > a img {
    height: 100%;
  }
  .xs-menu .top > div {
    color: #fff;
    display: flex;
    align-items: center;
  }
  .xs-menu .top > div > p {
    margin-right: 10px;
  }
  .xs-menu .top > div .mobile-toggle {
    align-content: center;
    touch-action: manipulation;
    border: 0;
    cursor: pointer;
    display: block;
    font: inherit;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    outline: 0;
    background: none;
    padding: 0;
    height: 23px;
    margin-top: -5px;
  }
  .xs-menu .top > div .mobile-toggle__icon {
    width: 25px;
    height: 2px;
    background: #fff;
    display: block;
    margin-top: 7px;
    transition: height 0.1s ease 0.3s;
  }
  .xs-menu .top > div .mobile-toggle__icon::after,
  .xs-menu .top > div .mobile-toggle__icon::before {
    content: "";
    width: 25px;
    height: 2px;
    background: #fff;
    display: block;
    position: relative;
    top: 5px;
    transition: top 0.3s ease 0.3s, transform 0.3s ease;
  }
  .xs-menu .top > div .mobile-toggle__icon::before {
    top: -7px;
  }
  .xs-menu .top > div .mobile-toggle.active .mobile-toggle__icon {
    height: 0;
  }
  .xs-menu .top > div .mobile-toggle.active .mobile-toggle__icon::after {
    top: -2px;
    transform: rotate(45deg);
    transition: top 0.3s ease, transform 0.3s ease 0.3s;
  }
  .xs-menu .top > div .mobile-toggle.active .mobile-toggle__icon::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.3s ease, transform 0.3s ease 0.3s;
  }
  .xs-menu .sub {
    background: #fff;
    display: none;
  }
  .xs-menu .sub.active {
    display: block;
  }
  .xs-menu .sub .flex-parent {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .xs-menu .sub .flex-parent .s-wr,
  .xs-menu .sub .flex-parent a {
    color: #182d70;
    border-bottom: 1px solid #E2E4F6;
  }
  .xs-menu .sub .flex-parent .s-wr:nth-child(even),
  .xs-menu .sub .flex-parent a:nth-child(even) {
    border-left: 1px solid #E2E4F6;
  }
  .xs-menu .sub .flex-parent.large a {
    height: 70px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .xs-menu .sub .flex-parent.small .s-wr,
  .xs-menu .sub .flex-parent.small a {
    height: 55px;
    width: 33.33333333%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .xs-menu .sub .flex-parent.small .s-wr:nth-child(3),
  .xs-menu .sub .flex-parent.small a:nth-child(3),
  .xs-menu .sub .flex-parent.small .s-wr:nth-child(5),
  .xs-menu .sub .flex-parent.small a:nth-child(5) {
    border-left: 1px solid #E2E4F6;
  }
  .xs-menu .sub .flex-parent.small .s-wr:last-child a {
    display: inline-block;
    min-width: 48px;
    height: auto;
    padding-right: 13px;
    border-bottom: 0;
    background: url(../img/icons/icon-login.png) no-repeat right;
  }
  .xs-menu .sub .flex-parent.small .s-wr select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #182d70;
    background: url(../img/icons/icon-drop.png) no-repeat right;
    border: none;
    cursor: pointer;
    padding: 0 13px 0 0;
  }
  .xs-menu .sub .flex-parent.small .s-wr select:hover {
    font-weight: 600;
  }
  .xs-menu .sub .flex-parent.social a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.66666667%;
    height: 60px;
  }
  .xs-menu .sub .flex-parent.social a:nth-child(3),
  .xs-menu .sub .flex-parent.social a:nth-child(5) {
    border-left: 1px solid #E2E4F6;
  }
  .xs-menu .sub .flex-parent.social a svg {
    width: 22px;
    fill: #182d70;
    transition: 0.4s;
  }
  .xs-menu .sub .flex-parent.social a:hover svg {
    fill: #2c8ee0;
  }
  .top-carousel .owl-carousel .owl-prev button,
  .top-carousel .owl-carousel .owl-next button {
    display: none;
  }
  .partners .right {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .partners .right .sub-message {
    display: none;
  }
  .partners .right .timer > div {
    width: 47px;
  }
  .partners .right .timer > div u {
    width: 47px;
    height: 40px;
    font-size: 33px;
    margin-top: 2px;
  }
  .partners .right .partner p {
    text-align: center;
    margin-bottom: 5px;
  }
  .top-carousel .owl-carousel .item img {
    min-height: 100px;
  }
  .news .col-xs-12 {
    flex-direction: column;
  }
  .news .col-xs-12 .news-col {
    width: 100%;
  }
  .news .col-xs-12 .news-col a.large .info .headig {
    font-size: 15px;
  }
  .news .col-xs-12 .news-col a.large .info .description {
    font-size: 20px;
  }
  .news .col-xs-12 .news-col a.large .info .xs-text {
    font-size: 16px;
  }
  .news .col-xs-12 .news-col a.small {
    flex-direction: column;
  }
  .news .col-xs-12 .news-col a.small .img-wrapper {
    width: 100%;
    height: 350px;
  }
  .news .col-xs-12 .news-h {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .news .col-xs-12 .projects-col {
    width: 100%;
  }
  .news .col-xs-12 .projects-col {
    margin-top: 20px;
  }
  .news .col-xs-12 .projects-col .card {
    background: #ffffff;
    margin-bottom: 0;
  }
  .news .col-xs-12 .projects-col .card img {
    display: none;
  }
  .news .col-xs-12 .projects-col .card:before,
  .news .col-xs-12 .projects-col .card:after,
  .news .col-xs-12 .projects-col .card span:before {
    display: none;
  }
  .news .col-xs-12 .projects-col .card span {
    color: #182d70;
    display: block;
    position: static;
  }
  .news .col-xs-12 .projects-col .card span i {
    color: #182d70;
  }
  .news .col-xs-12 .projects-col .card {
    border-radius: 0;
    border-bottom: 1px solid #182d70;
    height: auto;
  }
  .news .col-xs-12 .projects-col .card:first-child {
    border-radius: 5px 5px 0 0;
  }
  .news .col-xs-12 .projects-col .card:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom: none;
  }
  .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(2) {
    border-top-right-radius: 8px;
  }
  .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(n+3),
  .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(n+3) {
    display: none;
  }
  .video-player .player-h {
    background: none;
    padding-top: 0;
    padding-left: 0;
  }
  .video-player .flex-parent .video {
    height: 300px;
  }
  footer .container .col-xs-12 ul {
    flex-wrap: wrap;
  }
  footer .container .col-xs-12 ul li {
    margin-right: 0;
    padding: 0 10px;
  }
  footer .container .col-xs-12 ul li a {
    font-size: 15px;
  }
  footer .container .col-xs-12 .copy {
    font-size: 14px;
    line-height: 1.1;
  }
  a.section-link {
    padding-top: 55px;
  }
  .social-wall .social-indicators {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 30px;
  }
  .social-wall .social-indicators label:nth-child(2) {
    margin: 8px 0;
  }
  a.section-link span {
    font-size: 36px;
  }
  footer .container .col-xs-12 ul {
    margin-bottom: 15px;
  }
  .partners .left {
    align-items: center;
    justify-content: space-around;
  }
  .partners .left li:nth-child(3) img {
    max-width: 200px;
  }
  .partners .left li:nth-child(1) img {
    width: 90px;
  }
  .partners .left li:nth-child(2) img {
    width: 61px;
  }
  .partners .left li:nth-child(3) img {
    width: 110px;
  }
  .partners .right .partner {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-left: 10px;
    padding-right: 10px;
  }
  .partners .right .partner img {
    max-width: 170px;
  }
  .partners .right .partner p {
    font-size: 14px;
    text-align: left;
  }
  .partners .right .partner p br {
    display: none;
  }
  .statistics-table .table-h,
  .top-fancers .fancers-h {
    font-size: 28px;
  }
  .statistics-table .col-xs-12 .table-parent .table-parent-h {
    font-size: 20px;
  }
  .top-fancers .flex-parent .card {
    padding: 5px;
  }
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row.Search-options--open {
    display: flex;
  }
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators {
    border-right: none;
    width: 100%;
    padding-left: 0!important;
    justify-content: flex-start;
  }
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators label {
    min-width: 90px;
  }
  .athletes-rankings .filter-wrapper .sub-row .select-row {
    margin-top: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .athletes-rankings .filter-wrapper .sub-row .select-row select {
    margin-right: 0;
    margin-bottom: 10px;
    width: 252px;
  }
  .athletes-rankings .filter-wrapper .sub-row .select-row a.download {
    max-width: 252px;
    white-space: nowrap;
    padding: 0 52px;
  }
  .athletes-rankings .filter-wrapper .top-row .check-container {
    background: #dbdbdb;
    padding: 10px 0 10px 20px;
  }
  .athletes-rankings .flex-cards .card {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(n+4),
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(n+4) {
    display: none;
  }
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(3),
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(3) {
    display: table-cell;
  }
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(2) {
    border-top-right-radius: 0;
  }
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th:nth-child(3) {
    border-top-right-radius: 8px;
  }
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr td,
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table tr th,
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .text-center ul.pager li {
    font-size: 15px;
  }
  .athletes-rankings .flex-parent .athletes-rankings-h {
    font-size: 22px;
  }
  .athletes-rankings .flex-parent .links a {
    min-width: 130px;
    line-height: 30px;
  }
  .championnats-asiatiques {
    padding: 40px 0 60px 0;
  }
  .championnats-asiatiques .date {
    font-size: 18px;
  }
  .championnats-asiatiques .championnats-asiatiques-h {
    font-size: 38px;
    margin-bottom: 28px;
  }
  .championnats-asiatiques .flex-parent > p {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .championnats-asiatiques .overview .flex-overview .right a {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .championnats-asiatiques .overview {
    padding: 15px 15px 1px 15px;
  }
  .flex-overview .left {
    width: 50%;
  }
  .flex-overview .center {
    width: 50%;
  }
  .championnats-asiatiques .overview .flex-overview .left > p {
    margin-bottom: 20px;
  }
  .championnats-asiatiques .overview .flex-overview .center > div {
    margin-bottom: 13px;
    padding-top: 7px;
  }
  .championnats-asiatiques .overview .flex-overview .left > p {
    font-size: 19px;
  }
  .championnats-asiatiques .overview .flex-overview .center > div p {
    font-size: 19px;
    margin-bottom: 5px;
  }
  .championnats-asiatiques .overview .flex-overview .center > div b {
    font-size: 15px;
  }
  .championnats-asiatiques .overview .flex-overview .center > div b img {
    vertical-align: 0;
    max-width: 17px;
  }
  .clean-sport-flex-parent .col-xs-12 .left {
    padding-right: 0;
  }
  .clean-sport-flex-parent .col-xs-12 .right {
    display: none;
  }
  .clean-sport-flex-parent .col-xs-12 .left > div > p {
    font-size: 20px;
  }
  .clean-sport-flex-parent .col-xs-12 .left > div > a {
    font-size: 16px;
    padding: 10px 15px;
  }
  .clean-sport-flex-parent .col-xs-12 .left > div {
    margin-bottom: 40px;
  }
  .clean-sport-flex-parent .col-xs-12 {
    flex-direction: column;
  }
  .top-xs-show {
    display: block;
    margin-bottom: 20px;
  }
  .top-xs-show select {
    max-width: 300px;
    width: 100%;
    height: 50px;
    padding-left: 30px;
    padding-right: 35px;
    border: 1px solid #adadac;
    border-radius: 2px;
    font-size: 18px;
    color: #182d70;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../img/icons/icon-down.png) no-repeat 94%;
    background-color: #fff;
  }
  .clean-sport-header .clean-sport-header-h {
    font-size: 50px;
  }
  .clean-sport-header {
    min-height: 193px;
    background: url(../img/smog-banner-dark.jpg) no-repeat center;
    padding: 60px 15px 15px 15px;
  }
  .executive-committee-header .col-xs-12 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .executive-committee-header .col-xs-12 .info h1 {
    font-size: 29px;
    margin-bottom: 15px;
  }
  .executive-committee-header .col-xs-12 .info span {
    font-size: 16px;
    margin-bottom: 20px;
    display: block;
  }
  .executive-committee-header .col-xs-12 .info span br {
    display: none;
  }
  .executive-committee-header .col-xs-12 .list-menu a.heading,
  .executive-committee-header .col-xs-12 .list-menu ul li a {
    font-size: 16px;
  }
  .executive-committee-header .col-xs-12 .list-menu ul li a {
    padding: 8px 0;
  }
  .executive-committee-header .col-xs-12 .list-menu a.heading {
    margin-bottom: 8px;
  }
  .executive-committee .col-xs-12 .card {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .executive-committee .col-xs-12 .card .info .sub {
    margin-top: 10px;
  }
  .executive-committee .col-xs-12 .card .info .sub > a {
    padding: 4px 8px;
  }
  .executive-committee .col-xs-12 .card .info .sub > a {
    font-size: 15px;
  }
  .media-res-social .flex-top-parent {
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .media-res-social .flex-top-parent .card {
    width: 100%;
    margin-bottom: 20px;
  }
  .media-res-social .flex-sub-parent {
    flex-wrap: wrap;
  }
  .media-res-social .flex-sub-parent a {
    margin-bottom: 10px;
    width: 50%;
  }
  .athletes-rankings-table.media-res .media-res-menu li a {
    font-size: 14px;
  }
  .athletes-rankings-table.media-res .media-res-h {
    margin-bottom: 17px;
    font-size: 23px;
  }
  .media-res-link span {
    font-size: 32px;
  }
  .media-res-link span i {
    font-size: 31px;
  }
  .magazine-cards .col-xs-12 .card {
    width: 100%;
  }
  .magazine-select .slect-wrapper {
    padding: 10px 15px;
    width: 100%;
  }
  .magazine-cards ul.pager li span,
  .magazine-cards ul.pager li a {
    padding: 6px;
  }
  .magazine-select .slect-wrapper span {
    display: block;
    line-height: 1;
    margin-right: 5px;
  }
  .magazin-flex-top .col-xs-12 {
    flex-direction: column;
  }
  .magazin-flex-top .col-xs-12 .left {
    margin-right: 0;
  }
  .magazin-flex-top .col-xs-12 .right .txt {
    margin: 15px 0;
  }
  .magazine-cards {
    padding: 40px 0;
  }
  .magazin-flex-top {
    padding: 10px 0 40px 0;
  }
  .magazine-cards .col-xs-12 .card img {
    height: auto;
  }
  .magazine-cards.media-photos .col-md-12.text-center p {
    font-size: 30px;
  }
  .media-tab .nav.nav-tabs {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
  }
  .media-tab .nav.nav-tabs li {
    width: 20%;
  }
  .media-tab .tab-content .flex-parent {
    flex-direction: column;
  }
  .media-tab .tab-content .flex-parent .card {
    width: 100%;
  }
  .media-tab .tab-content .flex-parent .card img {
    width: 90px;
    height: 90px;
  }
  .media-tab .tab-content .flex-parent .card span {
    line-height: 1;
  }
  .media-tab .tab-content .heading {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .media-tab .nav.nav-tabs li a {
    font-size: 14px;
  }
  .media-tab .tab-content .flex-parent {
    margin-bottom: 40px;
  }
  .meetings-wrapper .meetings-menu li {
    margin-right: 10px;
  }
  .meetings-wrapper .meetings-menu li a {
    font-size: 11px;
  }
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td,
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(1),
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr td:nth-child(2) {
    font-size: 16px;
  }
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td,
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(1),
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(2) {
    font-size: 16px;
    padding: 6px;
  }
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(1) {
    padding-left: 10px;
  }
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(3) p {
    font-size: 14px;
  }
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(n+4),
  .athletes-rankings-table .statistics-table .col-xs-12 .table-parent .table a th:nth-child(n+4),
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a td:nth-child(n+7),
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table a th:nth-child(n+7) {
    display: none;
  }
  .athletes-rankings.results-competitions .calendar-links {
    flex-direction: column;
  }
  .athletes-rankings.results-competitions .calendar-links a {
    width: 100%;
  }
  .athletes-rankings.results-competitions .calendar-links a:first-child {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .athletes-rankings-table {
    padding: 40px 0;
  }
  .athletes-rankings.results-competitions .flex-parent .links span {
    display: none;
  }
  .athletes-rankings.results-competitions .flex-parent .links a {
    font-size: 15px;
  }
  .athletes-rankings.results-competitions .flex-parent .links a {
    min-width: 100%;
    display: inline;
  }
  .statistics-table .col-xs-12 .table-parent .text-center ul.pager li a,
  .statistics-table .col-xs-12 .table-parent .text-center ul.pager li span {
    padding: 8px;
  }
  .athletes-rankings-table.results-competitions-table .statistics-table .col-xs-12 .table-parent .table tr th {
    font-size: 14px;
  }
  .wach-sub-filter ul.pager li a,
  .wach-sub-filter ul.pager li span {
    padding: 8px;
  }
  .wach-sub-filter .wach-cards {
    flex-direction: column;
  }
  .wach-sub-filter .wach-cards .card {
    width: 100%;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
  }
  .wach-sub-filter .serach-f-wrapper input[type=submit] {
    min-width: 75px;
    right: 0;
  }
  .athletes-rankings .filter-wrapper .top-row .search-container input,
  .wach-sub-filter .serach-f-wrapper input {
    font-size: 16px;
  }
  .wach-main-player .wach-player-wrapper .frame-wr {
    height: 300px;
  }
  .wach-main-player .wach-h {
    font-size: 26px;
  }
  .partners .right .partner p {
    font-size: 11px;
  }
  .partners {
    margin: 0;
    flex-direction: column;
    background: #e9e9f1;
    padding: 0;
  }
  .partners .left {
    position: relative;
    z-index: 3;
  }
  .partners .right {
    margin-right: auto;
    margin-bottom: 0;
    padding-bottom: 7px;
    border-bottom: 1px solid #e0e0e7;
    box-shadow: 0 10px 29px #d8d9df;
    border-top: 1px solid #2a226f;
    background: #fff;
  }
  .partners .right .timer > div u {
    width: 40px;
    height: 27px;
    font-size: 21px;
    margin-top: 2px;
  }
  .partners .right .timer > div {
    margin-left: 2px;
    font-size: 12px;
  }
  .partners .right .partner img {
    max-width: 160px;
  }
  .news {
    padding-top: 35px;
  }
  .social-wall .col-sm-4 {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .social-wall .col-sm-4:nth-child(2) .card:nth-child(3) {
    display: none;
  }
  .social-wall .col-sm-4:first-child .card:nth-child(3) {
    display: none;
  }
  .social-wall .col-sm-4 .card {
    width: 48%;
    margin-bottom: 13px;
  }
  .social-wall .col-sm-4 .card span.info {
    height: 131px;
    font-size: 14px;
  }
  .social-wall .col-sm-4 .card img {
    height: 120px;
    object-fit: cover;
  }
  .social-wall .col-sm-4 .card .soc {
    position: relative;
    z-index: 4;
  }
  .social-wall .col-sm-4 .card .soc i {
    line-height: 1;
  }
  .social-wall .col-sm-4 .card.facebook .soc {
    height: 43px;
  }
  .social-wall .col-sm-4 .card.facebook .soc i {
    padding-top: 7px;
    padding-left: 13px;
    background-position-y: 5px;
  }
  .safe-sport .top-links {
    overflow: hidden;
  }
  .safe-sport-header .safe-sport-header-h {
    font-size: 30px;
    margin-bottom: 45px;
  }
  .safe-sport-header span {
    font-size: 16px;
  }
  .safe-sport .top-links p {
    font-size: 21px;
  }
  .safe-sport .top-links span {
    font-size: 14px;
  }
  .safe-sport .form-tile {
    padding: 15px;
  }
  .safe-sport .form-tile .tile-h {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .safe-sport .form-tile .inf.inf1,
  .safe-sport .form-tile .inf.inf2 {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .safe-sport .form-tile label.radio {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .safe-sport .form-tile label.form-group span {
    font-size: 16px;
  }
  .safe-sport .form-tile label.checkbox span {
    font-size: 16px;
    margin-bottom: 0;
  }
  .safe-sport .form-tile .inf.inf3 {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .safe-sport .form-tile label.checkbox span a {
    font-size: 13px;
  }
  .safe-sport .next-block p {
    margin-bottom: 30px;
  }
  .safe-sport {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .safe-sport-header {
    padding-top: 30px;
    padding-bottom: 15px;
  }
  .athletes-rankings .filter-wrapper .top-row .check-container {
    position: static;
    transform: translateY(0);
    width: 100%;
    justify-content: flex-end;
    padding-left: 0;
  }
  .athletes-rankings .filter-wrapper .top-row {
    padding-bottom: 10px;
    padding-top: 15px;
  }
  .athletes-rankings .filter-wrapper .top-row .Search-dropdown {
    margin-right: 0;
  }
  .athletes-rankings .filter-wrapper .sub-row {
    padding: 0;
    background: transparent;
  }
  .athletes-rankings .filter-wrapper .sub-row .checkbox-row .indicators {
    margin: 10px 0 0 0;
    background: #fff;
    padding: 10px 15px!important;
    border-radius: 5px;
  }
  .athletes-rankings .filter-wrapper .top-row {
    border-radius: 5px;
  }
  .athletes-rankings .filter-wrapper .sub-row .select-row select {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
  }
  .athletes-rankings .filter-wrapper .sub-row .select-row a.download {
    box-shadow: none;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    background: rgba(255, 255, 255, 0.9);
  }
  .athletes-rankings .flex-cards .card .cdn-normalize {
    height: 280px;
  }
  .athletes-rankings .flex-cards .card .count {
    margin: 10px auto;
  }
  .athletes-rankings .flex-parent .links {
    text-align: right;
  }
  .athletes-rankings .flex-parent .links a.active {
    display: inline-block;
    margin: 4px 0 4px 0;
  }
  .executive-committee-header .col-xs-12 .info h1 {
    font-size: 26px;
    margin-bottom: 7px;
  }
  .executive-committee-header {
    padding: 20px 0;
  }
  .executive-committee-header .col-xs-12 .info p,
  .executive-committee-header .col-xs-12 .info .sub_text {
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
  }
  .executive-committee-header .col-xs-12 .list-menu ul {
    display: none;
  }
  .executive-committee-header .col-xs-12 .list-menu {
    width: 100%;
  }
  .executive-committee-header .col-xs-12 .list-menu select {
    display: block;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    padding-right: 35px;
    border: 1px solid #adadac;
    border-radius: 2px;
    margin-right: 55px;
    font-size: 18px;
    color: #182d70;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../img/icons/icon-down.png) no-repeat 90%;
    background-color: #fff;
  }
  .executive-committee {
    padding: 20px 0;
  }
  .executive-committee .col-xs-12 .card .info {
    text-align: center;
  }
  .executive-committee .col-xs-12 .card .info .sub div {
    width: 100%;
  }
  .executive-committee .col-xs-12 .card .info .sub div p {
    text-align: center;
  }
  .executive-committee .col-xs-12 .card .info .sub {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
  .executive-committee .col-xs-12 .card .info .sub > a {
    margin-top: 10px;
  }
  .executive-committee .col-xs-12 .card .info .top p {
    font-size: 18px;
    margin-top: 10px;
  }
  .executive-committee .col-xs-12 .card .info .top p br {
    display: none;
  }
  .executive-committee .col-xs-12 .card {
    max-width: 385px;
    margin-left: auto;
    margin-right: auto;
  }
  .clean-sport-header {
    min-height: 82px;
    background: url(../img/smog-banner-dark.jpg) no-repeat center;
    padding: 20px 15px 15px 15px;
  }
  .clean-sport-header .clean-sport-header-h {
    font-size: 41px;
  }
  .meetings-wrapper {
    padding: 15px 0 10px 0;
  }
  .athletes-rankings-table.media-res.meetings-table {
    padding-bottom: 30px;
  }
  .magazine-cards {
    padding: 20px 0;
  }
  .magazine-cards .col-xs-12 .card {
    padding: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .magazine-cards .col-xs-12 .card img {
    width: 115px;
    height: 92px;
    object-fit: cover;
  }
  .magazine-cards .col-xs-12 .card .info {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 0;
    text-align: left;
    padding-left: 17px;
  }
  .magazine-cards .col-xs-12 .card .info u,
  .magazine-cards .col-xs-12 .card .info em {
    font-size: 17px;
  }
  .magazine-cards ul.pager {
    margin: 15px auto 0 auto;
  }
  .magazin-flex-top .col-xs-12 {
    flex-direction: row;
    align-items: center;
  }
  .magazin-flex-top .col-xs-12 .right .number {
    font-size: 18px;
  }
  .magazin-flex-top .col-xs-12 .right .txt {
    margin: 2px 0 5px 0;
    line-height: 1;
    font-size: 18px;
  }
  .magazin-flex-top .col-xs-12 .right .signup a,
  .magazin-flex-top .col-xs-12 .right .read a {
    padding: 0 10px;
    min-width: auto;
    font-size: 14px;
    line-height: 26px;
    min-width: 125px;
  }
  .magazin-flex-top .col-xs-12 .right .read {
    margin-bottom: 7px;
  }
  .magazin-flex-top .col-xs-12 .right {
    padding-top: 0;
  }
  .magazin-flex-top .col-xs-12 .left {
    width: 140px;
    margin-right: 10px;
  }
  .magazin-flex-top {
    padding: 3px 0 20px 0;
  }
  .magazine-select {
    padding: 15px;
  }
  .athletes-rankings-table.media-res {
    padding-top: 20px;
  }
  .athletes-rankings-table.media-res {
    padding-bottom: 20px;
  }
  .media-res-social {
    padding: 20px 0 25px 0;
  }
  .media-res-social .flex-top-parent {
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .media-res-social .flex-top-parent .card {
    padding: 5px;
  }
  .media-res-social .flex-top-parent .card p {
    font-size: 14px;
    margin-bottom: 0;
    padding: 3px 0px 5px 0px;
  }
  .media-res-social .flex-top-parent .card {
    width: 32%;
    margin-bottom: 11px;
  }
  .magazine-cards .col-xs-12 .card .info em,
  .magazine-cards .col-xs-12 .card .info u {
    font-size: 15px;
  }
  .magazine-cards ul.pager li {
    font-size: 21px;
  }
  .championnats-asiatiques .championnats-asiatiques-h {
    font-size: 23px;
    margin-bottom: 20px;
  }
  .championnats-asiatiques .flex-parent {
    margin-bottom: 20px;
  }
  .championnats-asiatiques .date {
    font-size: 16px;
  }
  .championnats-asiatiques {
    padding: 20px 0 40px 0;
  }
  .safe-sport .form-tile label.form-group select,
  .safe-sport .form-tile label.form-group input,
  .safe-sport .form-tile label.form-group textarea {
    font-size: 16px;
  }
  .safe-sport .next-block button {
    font-size: 16px;
    height: 45px;
  }
  .baners-bot__item .icn .text p {
    font-size: 14px !important;
  }
}
@media (max-width: 340px) {
  .media-res-social .flex-top-parent .card p {
    font-size: 12px;
  }
  .athletes-rankings .flex-cards .card .cdn-normalize {
    height: 210px;
  }
}
@media (max-width: 440px) {
  .news .col-xs-12 .news-col a.small .img-wrapper {
    height: 250px;
  }
}
.meta-description--content {
  display: inline-block;
  font-size: 25px;
  line-height: 1.2;
  margin-top: 10px;
  color: white;
}
#veteranAgeDropdownButton {
  background: transparent;
  padding: 0;
  margin-right: 18px;
  display: flex;
  align-items: center;
}
i.checkbox-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #adadac;
  border-radius: 5px;
  position: relative;
  margin-right: 8px;
}
i.checkbox-icon:before {
  content: "";
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background: url(../img/icons/check2.png) no-repeat center;
  background-size: 12px 9px;
  background-color: #fff;
  border-radius: 5px;
  transform: scale(0);
  transition: 200ms;
}
i.checkbox-icon.checked {
  border: 1px solid #182d70;
}
i.checkbox-icon.checked:before {
  transform: scale(1, 1);
}
#js-veteran-age-group-dropdown li.selected {
  background-color: #508fc5;
}
